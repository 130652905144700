
import styled from 'styled-components';

export const FooterContainerNav = styled.div`
display: flex;
flex-direction:column;
color: ${(props) => props.color || ' '};
background-color: ${(props) => props.backcolor || '#F6F6F8'};
padding-top: ${(props) => props.paddingt || ' '};
padding: ${(props) => props.pad || '45px 0 20px'};
 bottom: 0!important;
 width: 100%;
 text-align: center;
 height:${(props) => props.h || ' '};
display: ${(props) => props.displayW || ' '};

@media (max-width: 700px) {
  display: ${(props) => props.displayM || ' '};

}
`;

export const ContainerLogo = styled.div`
width: 31%;
margin-left: 75px;
`;

export const FooterContainerItemsNav = styled.div`
display: flex;
flex-direction:row;
padding: ${(props) => props.padding || ' '};
padding-top: ${(props) => props.paddingt || ''};
padding-bottom: ${(props) => props.paddingMobile || ' '};
margin: ${(props) => props.m || '0px 200px 0px 200px'};
color: ${(props) => props.color || 'black'};
background-color: ${(props) => props.backcolor || ''};
height:350px;
 @media (max-width: 700px) {
  display: ${(props) => props.displayM || ' '};
height:505px;

}
@media (min-width: 480px) {
  display: flex;
  padding-top: ${(props) => props.paddingWeb || '7px'};
  padding-bottom: ${(props) => props.paddingBWeb || ' '};

}
`;

// eslint-disable-next-line camelcase


// eslint-disable-next-line camelcase
export const FooterVerticalsA = styled.a`
 
  margin: 0;
  text-align: left;
  width: ${(props) => props.width || '100%'};
  margin-left: ${(props) => props.left || ' '};
  font-family: eliotprobold;
  font-style: normal;
  font-weight: bold;
  cursor: ${(props) => props.cursor || 'pointer'};
  font-size: ${(props) => props.size || '16px'};
  line-height: ${(props) => props.line || '255.5%;'};
  color:#061D80;
  /* or 41px */
  &:hover {
    color:#061D80;

  }
  letter-spacing: -0.3px;
  display: ${(props) => props.displayD || 'block'};
  @media (max-width: 700px) {
      display: ${(props) => props.displayM || 'block'};
  }
`;





// eslint-disable-next-line camelcase
export const FooterDiv3 = styled.div`
display: flex;
flex-direction:column ;
width: auto;
@media (min-width: 480px) {
  width: 100%;
  padding-left: ${(props) => props.pl || ' '};
}
@media (max-width: 700px) {
  display: ${(props) => props.displaymobile || 'none'};
}
`;


// eslint-disable-next-line camelcase
export const FooterDiv1 = styled.div`
width: auto;
@media (min-width: 480px) {
  width: ${(props) => props.width || '50%'};
  text-aling: left;
  display: none;
}
@media (max-width: 700px) {
  display: ${(props) => props.displaymobile || 'block'};
}
`;
// eslint-disable-next-line camelcase
export const FooterDiv2 = styled.div`
width: auto;
 margin-top: ${(props) => props.mtop|| ' '};
@media (min-width: 480px) {
  width: ${(props) => props.width || '50%'};
  margin-top: ${(props) => props.mtop|| ' '};
  display: none;

}


`;

export const FooterVerticals = styled.a`

margin: 0;
  text-align: left;
  width: ${(props) => props.width || '100%'};
  margin-left: ${(props) => props.left || ' '};
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  cursor: ${(props) => props.cursor || 'pointer'};
  font-size: ${(props) => props.size || '16px'};
  line-height: ${(props) => props.line || '255.5%;'};
  color: black;
  /* or 41px */
  text-decoration:none;
  &:hover {
   color: black;
  text-decoration: underline;
  }
  letter-spacing: -0.3px;
  display: ${(props) => props.displayD || 'block'};
  @media (max-width: 700px) {
      display: ${(props) => props.displayM || 'block'};
  }
`;

export const FooterSubTitle = styled.h2`

margin: 0;
  text-align: left;
  width: ${(props) => props.width || '100%'};
  margin-left: ${(props) => props.left || ' '};
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  
  font-size: ${(props) => props.size || '16px'};
  line-height: ${(props) => props.line || '255.5%;'};
  color: black;
  /* or 41px */

  
  letter-spacing: -0.3px;
  display: ${(props) => props.displayD || 'block'};
  @media (max-width: 700px) {
      display: ${(props) => props.displayM || 'block'};
  }
`;




export const ContainerFooterLinks = styled.div`
width:80%;
display:flex;
flex-direction: row;
justify-content: space-between;
margin:0px 0px 20px 110px; 
`

export const FooterA = styled.a`
 
  margin: 0;
 
  width: ${(props) => props.width || '100%'};
  margin-left: ${(props) => props.left || ' '};
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  cursor: ${(props) => props.cursor || 'pointer'};
  font-size: ${(props) => props.size || '16px'};
  line-height: ${(props) => props.line || '255.5%;'};
  color:#0091DA;
  text-decoration:none;
  /* or 41px */
  &:hover {
    color:#0091DA;
    text-decoration:none;
  }
  letter-spacing: -0.3px;
  display: ${(props) => props.displayD || 'block'};
  @media (max-width: 700px) {
      display: ${(props) => props.displayM || 'block'};
  }
`;


export const ParfF = styled.p`
 font-size:  ${(props) => props.mfonts || ' 11px'};
 /*background-color: #fff;*/
color: ${(props) => props.color || 'black'};
margin-left:${(props) => props.ml || ' '}; 
border: none;

`;
export const ContainerItems = styled.div`
display:${(props) => props.dis || ' flex '} ;
flex-direction:${(props) => props.dis || ' '} ; 
width:${(props) => props.w || '100%'}   ;
height:${(props) => props.h || ' '}  ;
margin:${(props) => props.m || ' '};
padding:${(props) => props.pad || ' '};
margin-left:${(props) => props.left || ' '};
background:${(props) => props.bgck || ''} ;
opacity:${(props) => props.op || ' '} ; 
align-items:${(props) => props.ai || 'center'};
justify-content:${(props) => props.jc || 'center'};
max-width: ${(props) => props.mw || '81rem;'};
border:${(props) => props.b || ' '};
border-radius:${(props) => props.br || ' '};
@media (max-width: 1300px) {
  
  padding:${(props) => props.pad1300 || ' '};

}
@media (max-width: 900px) {
  flex-direction:${(props) => props.disMob || ' '} ;
  border:${(props) => props.b900 || ' '};
  padding:${(props) => props.pad900 || ' '};
  height:${(props) => props.h900 || ''}  ;
  margin:${(props) => props.m900 || ''};
}

@media (max-width: 800px) {
  
  padding:${(props) => props.pad800 || ' '};
  /* font-size:30px */
}
`;


export const FooterRow = styled.div`
display:${(props) => props.dis || ' flex '} ;
flex-wrap: ${(props) => props.fw || ' wrap '} ;
width:${(props) => props.w || '100%'};
align-items: center;
align-content:${(props) => props.ac || ' center '};
justify-content:${(props) => props.jc || 'center'};
padding:${(props) => props.pad || ' '};
margin: ${(props) => props.m || '14px 0'};
font-family: ${(props) => props.ff || 'eliotpro'};
font-weight: ${(props) => props.fw || ' '};
text-align: ${(props) => props.txal || ' '};
@media (max-width: 900px ){
  display:${(props) => props.dis900 || ' flex '} ;
  width:${(props) => props.w900 || ' '}   ;
  padding: ${(props) => props.pad900 || 'unset'} ;
  margin: ${(props) => props.m900 || ' '}; 
 
}


@media (max-width: 550px ){
  display:${(props) => props.dis900 || ' flex '} ;
  width:${(props) => props.w900 || ' '}   ;
  padding: ${(props) => props.pad900 || 'unset'} ;
  margin: ${(props) => props.m900 || ' '}; 
 text-align: ${(props) => props.tal900 || ' '}; 
}
`;

export const FooterCol = styled.div`
display:${(props) => props.dis || ' block '} ;
flex-wrap: ${(props) => props.wrap || ' wrap '} ;
width:${(props) => props.w || ' '};
max-width:${(props) => props.mw || ' '};
min-height:${(props) => props.minh || ' '};
align-items: center;
align-content:${(props) => props.ac900 || ' center '};
justify-content:${(props) => props.jc || 'center'};
padding:${(props) => props.pad || ' '};
margin:${(props) => props.m || ' 0 7px '};
background-color:${(props) => props.bgcolor || ' '};
font-family: ${(props) => props.ff || 'eliotpro'};
@media (max-width: 900px ){
  display:${(props) => props.dis900 || ' '} ;
  width:${(props) => props.w900 || ' '}   ;
  padding: ${(props) => props.pad900 || 'unset'} ; 
  margin: ${(props) => props.m900 || ' '}; 
}

@media (max-width: 550px ){
  width:${(props) => props.w550 || ''}; 
  min-height:${(props) => props.minh550 || ' '};
}

`;

export const FooterLink = styled.a`
 
  margin: 0;
 
  width: ${(props) => props.width || '100%'};
  margin-left: ${(props) => props.left || ' '};
  font-family: eliotpro;
  font-style: normal;
  font-weight: 400;
  cursor: ${(props) => props.cursor || 'pointer'};
  font-size: ${(props) => props.size || '16px'};
  line-height: ${(props) => props.line || ' '};
  color: #616267 !important;
  text-decoration-style: dotted;
  text-underline-position: under;
  text-decoration:${(props) => props.tdec || ' '};
  &:hover {
    color:#0091DA;
    text-decoration:none;
  }
  
  display: ${(props) => props.displayD || 'block'};
  @media (max-width: 700px) {
      display: ${(props) => props.displayM || 'block'};
  }
`;

export const Divider = styled.div`
width:${(props) => props.w || '0'};
height:${(props) => props.h || '15px'};
border: solid;
border-width: 0 0 0 2px;
border-color: #616267;
margin: ${(props) => props.m || '0 7px'};
`;
