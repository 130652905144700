/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import {
  FooterContainerNav,
  FooterRow,
  FooterCol,
  FooterLink,
  Divider
} from "../../assets/styles/Footer.styled";
import fbImg from './../../assets/images/SectionShare/FaceBook.svg'
import twImg from './../../assets/images/SectionShare/Twitter.svg'
import lnImg from './../../assets/images/SectionShare/Linkedin.svg'
import ytImg from './../../assets/images/SectionShare/Youtube.svg'
import phoneImg from './../../assets/images/SectionShare/phone.svg'
const FooterNav = () => {
  return (
    <Fragment>
      <FooterContainerNav>

        <FooterRow className="socials">
          <FooterCol>
            <a target="_blank" href="https://www.facebook.com/principal.mexico.oficial/" rel="noreferrer">
              <img width="24px" height="24px" src={fbImg} alt="LogoFacebook" />
            </a>
          </FooterCol>
          <FooterCol>
            <a target="_blank" href="https://twitter.com/Principal_mx" rel="noreferrer">
              <img width="24px" height="24px" src={twImg} alt="LogoTwitter" />
            </a>
          </FooterCol>
          <FooterCol>
            <a target="_blank" href="https://www.linkedin.com/company/principalfinancialgroup/" rel="noreferrer">
              <img width="24px" height="24px" src={lnImg} alt="LogoLinkedin" />
            </a>
          </FooterCol>
          <FooterCol>
            <a target="_blank" href="https://www.youtube.com/channel/UC891ECUCkClKOeSoKGB1kSQ" rel="noreferrer">
              <img width="24px" height="24px" src={ytImg} alt="LogoYoutube" />
            </a>
          </FooterCol>
        </FooterRow>
        <FooterRow className="contact-number">
          <FooterCol>
           
              <img width="17px" height="17px" src={phoneImg} alt="LogoPhone" />
           
          </FooterCol>
          <FooterCol>
            81-8056-4624
          </FooterCol>
        </FooterRow>
        <FooterRow className="faq">
          <FooterCol>
            <FooterLink target="_blank" href="https://www.principal.com.mx/avisos/aviso-de-privacidad">
              Privacidad
            </FooterLink>
          </FooterCol>
          <Divider />
          <FooterCol>
            <FooterLink target="_blank" href="https://www.principal.com.mx/mapa-de-sitio">
              Mapa del sitio
            </FooterLink>
          </FooterCol>
        </FooterRow>
        <FooterRow className="rights" m="10px 0 40px">
          2022, Principal México, Todos los derechos reservados.
        </FooterRow>

      </FooterContainerNav>
    </Fragment>
  );
};

export default FooterNav;
