import axios from "axios";

// rocket dev
// const ApiBaseUrl = 'https://aitpzz8ooc.execute-api.us-east-1.amazonaws.com/'

// principal dev
 const ApiBaseUrl = 'https://apiaforesfondos.auth.us-west-2.amazoncognito.com/'

const Api = axios.create({
  baseURL: ApiBaseUrl,
  headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
  }
});

export default Api;