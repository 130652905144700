import axios from "axios";

// rocket dev
const ApiBaseUrl = 'https://aitpzz8ooc.execute-api.us-east-1.amazonaws.com/'

// principal dev
// const ApiBaseUrl = 'https://8pb362sz08.execute-api.us-west-2.amazonaws.com/'

const Api = axios.create({
  baseURL: ApiBaseUrl,
  headers: {
      'Content-Type': 'application/json',
  }
});

export default Api;