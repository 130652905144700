import React, { Fragment } from "react";

import { MainHeader, Logo, ContainerItems } from "../../assets/styles/Header.styled";

import LogoHeader from "./../../assets/images/LogoHeader.svg";

const Header = (props) => {
  return (
    <>
      <MainHeader>
        <ContainerItems jc="flex-start">
          <a href="/" rel="noreferrer">
            <Logo src={LogoHeader} width="50%" alt="logo" />
          </a>
        </ContainerItems>

      </MainHeader>
    </>
  );
};

export default Header;
