import React, {Fragment} from "react";
import FooterNav from "./FooterNav";

const Footer = () => {
  return (
    <Fragment>
      <FooterNav />
    </Fragment>
  );
};

export default Footer;
