import React, { useState, useEffect, useRef, useContext } from "react";

import ResultComponent from "./QuestionaryResult";
import { useNavigate } from "react-router-dom";
 import { questions } from "./data/data.js";
import RowR from "./../assets/icons/rowr.svg";
import RowL from "./../assets/icons/rowl.svg";
import {
  ContainerQuestion,
  Prf,
  Question,
  ContainerOption,
  ContainerBtn,
  Merror,
  Label,
} from "./../assets/styles/Perfilador.styled";
import auth from "../config/auth/auth";
import lambdas from "../config/lambdas/lambdas";
import { Button } from "./../assets/styles/StylesGeneric.styled";
import { UserContext } from "../useContext/UserContext";
import './../assets/styles/StylesGeneric.css';

export default function Questions({
  inversionNivelRiesgo,
  setInversionNivelRiesgo,
  setIsPassingForPerfiliador,
  isSafari }) {
  const navigate = useNavigate();
  const [selected, setSelected] = useState("");
  const [questions1, setQuestions1] = useState("");
  const [questionNumber, setQuestionNumber] = useState();
  const [answerNumber, setAnswerNumber] = useState();
  const { uuid } = useContext(UserContext);
  const [idUser] = useState(sessionStorage.getItem('user_principal_user') || uuid)
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [error, setError] = useState("");
  const [perfil, setPerfil] = useState("");
  const [text, setText] = useState("");
  const [description, setDescription] = useState("");
  const [radioClass, setRadioClass]=useState(""); 
  const [radioPerfClass, setRadioPerfClass]=useState("");  
  const radiosWrapper = useRef();
  const [labelLeftCustom, setLabelLeftCustom]=useState(""); 

  useEffect(() => {
    const findCheckedInput = radiosWrapper.current.querySelector(
      "input:checked"
    );
    if (findCheckedInput) {
      findCheckedInput.checked = false;
    }
    if(isSafari){
      //console.log("Es Safari: ")
      //console.log(isSafari)
      setRadioClass("radio-input-safari")
      setRadioPerfClass("radio-perfilador-safari")
      setLabelLeftCustom("15px")
    }else{
      //console.log("Es Safari: ")
      //console.log(isSafari)
      setRadioClass("radio-input")
      setRadioPerfClass("radio-perfilador")
      setLabelLeftCustom(" ")

    }
  }, []);

  const changeHandler = (e, question, answer) => {
    setQuestionNumber(question);
    setAnswerNumber(answer)
    setSelected(e.target.value);
    if (error) {
      setError(" ");
    }
  };

  useEffect(() => {
        const token = auth.GetToken()
        .then((response) => {
         lambdas.Questions()
          .then((response) => {
            if (!response.data.Error) {     
              console.log(response.data.Message.result)
              setQuestions1(response.data.Message.result)
            }
          })
          .catch((error) => {
            console.log(error.data);
          });
        })
        .catch((error) => {
          alert('Ha ocurrio un error al hacer el cálculo. Contactar al equipo de soporte.');
          console.log(error.data);
        });
  }, []);



  const nextClickHandler = () => {
    console.log('selected', selected)

    if (selected === "") {
      return setError("Selecciona una respuesta para continuar");
    } 
    const nextQuestion = currentQuestion + 1;
    const data = {
      id_user: idUser,
      question: questionNumber,
      answer: answerNumber,
    }

    const token = auth.GetToken()
    .then((response) => {
      lambdas.Perfilador(data, response.data.access_token)
      .then((response) => {
        //console.log('response', response)
        if (!response.data.Error) {
          const { profile, text, description } = response.data.Message
          if (profile !== 'En proceso') {
            setInversionNivelRiesgo(profile)
            setPerfil(profile)
            setText(text)
            setDescription(description)
            setShowScore(true);
            setIsPassingForPerfiliador(true)
          }
          //console.log('response perfilador', response.data.Message)
        }
      })
      .catch((error) => {
        console.log(error.data);
      });
    })
    .catch((error) => {
      console.log(error.data);
    });
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    }
    setSelected("");

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowScore(true);
    }


  };

  const prevClickHandler = (e) => {
    const prevQuestion = currentQuestion - 1;
    if (prevQuestion < questions.length) {
      setCurrentQuestion(prevQuestion);
    }
    setSelected("");
    setError(" ");
  };

  const onReset = (e) => {
    e.preventDefault();
    setCurrentQuestion(0);
    setShowScore(false);
    navigate('/perfilador')
  };

  const redirect = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    navigate('/')
  };

  return (
    <div className="question-wrapper">
      {showScore ? (
        <>
          <ResultComponent
            perfil={perfil}
            text={text}
            description={description}
            onReset={onReset}
          />
          {/* You scored {score} out of {questions.length}  */}
          {/*ScoreF()*/}
        </>
      ) : (
        <ContainerQuestion>
          <div className="question-section">
            { }
            { }

            <Prf mwMob="100%">{questions[currentQuestion].initialText}</Prf>
            <Question>{questions[currentQuestion].question}</Question>
          </div>
          <div className="control" ref={radiosWrapper} style={{ width: '100%' }}>
            {questions[currentQuestion].answerOptions.map((answerOption, i) => (
              <ContainerOption key={i} at="flex-start" mb="20px" dis="block">
               <div className={radioPerfClass}>
                {/* <div className="radio-perfilador">*/}
                   {/*<div className="radio-input">*/}
                  <div className={radioClass}>
                    <input
                      id={answerOption.id}
                      value={answerOption.value}
                      checked={selected === answerOption.value ? true : false}
                      onChange={(e) => changeHandler(e, answerOption.question, answerOption.order)}
                      style={{ cursor: "pointer", margin: "2px 0", display: "block" }}
                      type="radio"
                      className="input-radio"
                    />
                  </div>

                  <Label w="95%" ml={labelLeftCustom} mb=" " pl="3px"  htmlFor={answerOption.id}>
                  {/*<Label w="95%"  mb=" " pl="3px"  htmlFor={answerOption.id}>*/}
                    {answerOption.answerText}<br />
                    {answerOption.answerText2}
                  </Label>
                </div>
              </ContainerOption>
            ))}
          </div>
          {error && <Merror>{error}</Merror>}
          <ContainerBtn>
            <Button
              w="133px"
              bgck="#ffff"
              Color="#0061A0"
              color="#0061A0"
              bder=" 1px solid #0061A0"
              HoverbackgroundColor="none"
              onClick={currentQuestion === 0 ? redirect : prevClickHandler}
            >
              <img style={{ marginRight: "10px" }} src={RowL} alt="Rowl" />{" "}
              Regresar
            </Button>
            <Button w="133px" ml="20px" onClick={nextClickHandler}>
              Avanzar{" "}
              <img style={{ marginLeft: "10px" }} src={RowR} alt="RowR" />{" "}
            </Button>
          </ContainerBtn>
        </ContainerQuestion>
      )}
    </div>
  );
}
