/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from "react";
import {
  SimuladorBox,
  Select,
  Small,
  TitleSimulador,
  LabelSimulador,
  ContainerLabelHelp,
  RiesgoPrf,
  SubContainer,
  ConatainerInputAndSelect,
  Limpiar,
  Dis,
  ContainerBox,
  ContainerIn,
} from "./../../assets/styles/Simulador.styled";
import { Inpt, Label, GrupoInput} from './../../assets/styles/Simulador.styled';
import { NumberFormatCustom } from './../../utilities/formatNumber';
import { Button } from "./../../assets/styles/StylesGeneric.styled";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import Tooltip from "./ToolTips";
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import {
  IsInversionInicial,
  IsInversionMensual,
  IsInversionNivelRiesgo,
  IsInversionLiquidez,
  IsDuracionTipo,
  IsDuracionPlazo,
} from "../../utilities/Validators";

import {
  IconoValidacion,
} from "./Input/elementos/Formularios.js";


import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import Table from "./SimulatorTable";
import { RenderIf } from "./../../utilities/RenderIf";
import lambdas from "../../config/lambdas/lambdas";
import auth from "../../config/auth/auth";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { UserContext } from "../../useContext/UserContext";
import ClipLoader from "react-spinners/ClipLoader";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "0px 0px 10px 0px",
  },
  input: {
    width: 45,
    height: 27,
    background: "#FFFFFF",
    border: "1px solid #333333",
    borderRadius: "4px",
    marginRight: "10px ",
    "*::-webkit-appearance": {
      "-webkit-appearance": "none",
    },
  },
  // bolita
  thumb: {
    background: "#00EFE8",
    width: "21px",
    height: "20.96px",
    boxShadow: "none !important",
    top: 10,
  },
  // puntos en el slider de la los rangos o marks

  // linea de fondo del slider
  rail: {
    background: "#616267",
    height: 2.8,
    // border: "1px solid black",
  },
  // linea slider
  track: {
    background: "#00EFE8",
    // border: "2px solid #00EFE8",
    height: 2.8,

    // borderRadius: 10
  },
});


const Simulador = ({ displayRiskLevel,
  displayTable,
  displayCta,
  padding,
  setCapitalAcumuladoNeutral,
  capitalAcumuladoNeutral,
  setCapitalAcumuladoMinimo,
  capitalAcumuladoMinimo,
  setCapitalAcumuladoMaximo,
  capitalAcumuladoMaximo,
  setCapitalInvertido,
  capitalInvertido,
  setRendimiento,
  rendimiento,
  inversionInicial,
  setInversionInicial,
  inversionMensual,
  setInversionMensual,
  inversionNivelRiesgo,
  setInversionNivelRiesgo,
  duracionTipo,
  setDuracionTipo,
  duracionPlazo,
  setDuracionPlazo,
  nivelPortafolio,
  setnivelPortafolio,
  setScrollTop
}) => {

  const [isHovering, setIsHovering] = useState(false);
  const classes = useStyles();
  const [value, setValue] = React.useState(10);
  const navigate = useNavigate();
  // const [inversionInicial, setInversionInicial] = useState("");
  //const [inversionMensual, setInversionMensual] = useState("");
  const [liquidez, setLiquidez] = useState("No");
  //const [inversionNivelRiesgo, setInversionNivelRiesgo] = useState(
  //  "Tradicional"
  //);
  // const [duracionTipo, setDuracionTipo] = useState("meses");
  // const [duracionPlazo, setDuracionPlazo] = useState("");
  const { uuid } = useContext(UserContext);
  const [idUser] = useState(sessionStorage.getItem('user_principal_user') || uuid)
  const [showRes, setShowRes] = useState(false);
  const [showBtnAplicar, setshowBtnAplicar] = useState(true);
  // const [isValid, setValid] = useState(false);
 
  // validaciones
  const [isValidInversionInicial, setIsValidInversionInicial] = useState();
  const [isValidInversionMensual, setIsValidInversionMensual] = useState();
  const [
    isValidInversionNivelRiesgo,
    setIsValidInversionNivelRiesgo,
  ] = useState(true);
  const [isValidInversionLiquidez, setIsValidInversionLiquidez] = useState(
    true
  );
  const [isValidDuracionTipo, setIsValidDuracionTipo] = useState(true);
  const [isValidDuracionPlazo, setIsValidDuracionPlazo] = useState(true);
  const [loading, setloading] = useState(false);

  // const cambioRadioRiesgo=e=>{
  //   setInversionNivelRiesgo(e.target.value);
  // }

  // const [pwd, setPwd] = useState();
  // const [confirmPwd, setConfirmPwd] = useState();


  const handleSliderChange = (event, newValue) => {
    setDuracionPlazo(newValue);
    setIsValidDuracionPlazo(IsDuracionPlazo(newValue,duracionTipo,true));
  };

  const handleInputChange = (event) => {
    //setValue(event.target.value === "" ? "" : Number(event.target.value));
    setDuracionPlazo(
      event.target.value === "" ? "" : Number(event.target.value)
    );
    setIsValidDuracionPlazo(IsDuracionPlazo(event.target.value,duracionTipo));
    //IsDuracionPlazo(duracionPlazo,duracionTipo);
  };



  // const handelOnChange = (e) => {
  // 	setInversionInicial(e.target.value);
  // }
  const handelOnChangeM = (e) => {
    setInversionMensual(e.target.value);
  }

  const handleBlur = () => {
    if (value < 0) {
      setDuracionPlazo(0);
    } else if (value > 50) {
      setDuracionPlazo(50);
    }
  };

  const validateFormSimple = () => {
    let readyForSend = true;

    if (!IsInversionInicial(inversionInicial, true)) {
      readyForSend = false
    }

    if (!IsInversionMensual(inversionMensual, true)) {
      readyForSend = false
    }

    if (!IsInversionNivelRiesgo(inversionNivelRiesgo)) {
      readyForSend = false
    }

    if (!IsInversionLiquidez(liquidez)) {
      readyForSend = false
    }

    if (!IsDuracionTipo(duracionTipo)) {
      readyForSend = false
    }

    if (!IsDuracionPlazo(duracionPlazo, duracionTipo,true)) {
      readyForSend = false
    }

    return readyForSend

  }

  const validateForm = () => {
    let readyForSend = true;

    if (!IsInversionInicial(inversionInicial, true)) {
      setIsValidInversionInicial(false);
      readyForSend = false;
    } else {
      setIsValidInversionInicial(true);
    }

    if (!IsInversionMensual(inversionMensual, true)) {
      setIsValidInversionMensual(false);
      readyForSend = false;
    } else {
      setIsValidInversionMensual(true);
    }

    if (!IsInversionNivelRiesgo(inversionNivelRiesgo)) {
      setIsValidInversionNivelRiesgo(false);
      readyForSend = false;
    } else {
      setIsValidInversionNivelRiesgo(true);
    }

    if (!IsInversionLiquidez(liquidez)) {
      setIsValidInversionLiquidez(false);
      readyForSend = false;
    } else {
      setIsValidInversionLiquidez(true);
    }

    if (!IsDuracionTipo(duracionTipo)) {
      setIsValidDuracionTipo(false);
      readyForSend = false;
    } else {
      setIsValidDuracionTipo(true);
    }

    if (!IsDuracionPlazo(duracionPlazo, duracionTipo,true)) {
      setIsValidDuracionPlazo(false);
      readyForSend = false;
    } else {
      setIsValidDuracionPlazo(true);
    }

    return readyForSend;
  };


  const validate = () => {
    return inversionInicial > 999 & (inversionMensual == 0 || inversionMensual >499);
  };
  const goToTop = () => {
   
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
    
  
};

  const sendFormListener = (e) => {
    e.preventDefault();
    if (validateForm()) {
      //console.log(scrollTop)
      // goToTop()
      let checkLiquidez = false
      if (liquidez === "Si") checkLiquidez = true
      const data = {
        inversionInicial: parseFloat(inversionInicial),
        inversionMensual: parseFloat(inversionMensual),
        inversionNivelRiesgo: inversionNivelRiesgo,
        inversionDuracionTipo: duracionTipo,
        inversionDuracionPlazo: parseInt(duracionPlazo),
        inversionLiquidez: checkLiquidez,      
        idSession: idUser
      }
      const config = {
       headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
      };
        /**
         * esta lambda es la de rocket
         */
        /*lambdas.CalcularSimulador(data)
        .then((response) => {
          if (!response.data.Error) {
             setCapitalAcumuladoNeutral(response.data.Message.capital_acumulado_neutral.toFixed(2))
             setCapitalAcumuladoMinimo(response.data.Message.capital_acumulado_minimo.toFixed(2))
             setCapitalAcumuladoMaximo(response.data.Message.capital_acumulado_maximo.toFixed(2))
             setCapitalInvertido(response.data.Message.capital_invertido.toFixed(2))
             setRendimiento(response.data.Message.rendimiento.toFixed(2))
            setnivelPortafolio(response.data.Message.nivel_portafolio)

          }
        })
        .catch((error) => {
          console.log(error.data);
        });*/

        /**
         * esta lambda es la de rocket + api principal
         */ 
        setloading(true)
        const token = auth.GetToken()
        .then((response) => {
         lambdas.CalcularSimuladorApi(data, response.data.access_token)
          .then((response) => {
            if (!response.data.Error) {               
              setCapitalAcumuladoNeutral(response.data.Message.responseApi.estimatedamount)                                        
              setCapitalAcumuladoMinimo(response.data.Message.responseApi.scenarioConservative)                         
              setCapitalAcumuladoMaximo(response.data.Message.responseApi.scenarioOptimistic)                
              setCapitalInvertido(response.data.Message.responseApi.capitalInvested )                            
              setRendimiento(response.data.Message.responseApi.yield)             
              setnivelPortafolio(response.data.Message.nivel_portafolio )
              setloading(false)         
            }
          })
          .catch((error) => {
            console.log(error.data);
          });
        })
        .catch((error) => {
          alert('Ha ocurrio un error al hacer el cálculo. Contactar al equipo de soporte.');
          console.log(error.data);
        });   
       setShowRes(true);
    }       
    setshowBtnAplicar();
  };


  function Perfil() {

    if (inversionNivelRiesgo === "Tradicional") {

      return (
        <>
          <div className="ContainerRadioInput" id="tradicional">
            <div class="circle-full">
            </div>
            <div class="line">
            </div>
            <div class="circle-empty">
            </div>
            <div class="line">
            </div>
            <div class="circle-empty">
            </div>
            <div class="line">
            </div>
            <div class="circle-empty">
            </div>
          </div>
        </>
      );
    }
    if (inversionNivelRiesgo === "Conservador") {
      return (
        <>
          <div className="ContainerRadioInput" id="conservador">
            <div class="circle-full">
            </div>
            <div class="line">
            </div>
            <div class="circle-full">
            </div>
            <div class="line">
            </div>
            <div class="circle-empty">
            </div>
            <div class="line">
            </div>
            <div class="circle-empty">
            </div>
          </div>
        </>
      );
    }
    if (inversionNivelRiesgo === "Moderado") {
      return (


        <>
          <div className="ContainerRadioInput" id="moderado">
            <div class="circle-full">
            </div>
            <div class="line">
            </div>
            <div class="circle-full">
            </div>
            <div class="line">
            </div>
            <div class="circle-full">
            </div>
            <div class="line">
            </div>
            <div class="circle-empty">
            </div>
          </div>
        </>

      );
    }
    if (inversionNivelRiesgo === "Agresivo") {
      return (
        <>
          <div className="ContainerRadioInput" id="agresivo" >
            <div class="circle-full">
            </div>
            <div class="line">
            </div>
            <div class="circle-full">
            </div>
            <div class="line">
            </div>
            <div class="circle-full">
            </div>
            <div class="line">
            </div>
            <div class="circle-full">
            </div>
          </div>

        </>
      );
    }
  }


  const [per, setPer] = React.useState(0);
  const handleClick = (event) => {

    switch (event.currentTarget.id) {
      case '1':
        setPer(3);
        setInversionNivelRiesgo("Tradicional");
        break;
      case '2':
        setPer(35);
        setInversionNivelRiesgo("Conservador");
        break;
      case '3':
        setPer(70);
        setInversionNivelRiesgo("Moderado");
        break;
      case '4':
        setPer(100);
        setInversionNivelRiesgo("Agresivo");
        break;
      default:
        break;
    }
  };




  return (
    <SimuladorBox p={padding}>
      <SubContainer>
        <TitleSimulador> Simula, personaliza y contrata en línea</TitleSimulador>

        <ContainerLabelHelp>
          <LabelSimulador mb="0">Inversión inicial</LabelSimulador>
          <Tooltip
            text="Este depósito puede ser desde $1,000 pesos."
            lf="135px"
            maxw400="116px"
          />
        </ContainerLabelHelp>

        <GrupoInput>

          <Inpt
            type="text"
            value={inversionInicial}
            onChange={(e) => {
              setInversionInicial(isNaN(e.target.value) ? "" : e.target.value)
              setIsValidInversionInicial(IsInversionInicial(e.target.value))
            }}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />

        </GrupoInput>
   

        {(isValidInversionInicial) && (  
              <><IconoValidacion
                  icon={isValidInversionInicial ? faCheckCircle : faTimesCircle}
                  valido={isValidInversionInicial}
                />
             </>
          )}

        {(isValidInversionInicial === false) && (  
              <><IconoValidacion
                  icon={isValidInversionInicial ? faCheckCircle : faTimesCircle}
          
                  valido={isValidInversionInicial}
                />
              <Small> Mínimo: $1,000</Small></>
          )}


        <ContainerLabelHelp>
          <LabelSimulador mb="0">Inversión mensual </LabelSimulador>{" "}
          <Tooltip
            text="Este monto se programará y tú puedes elegir la fecha."
            lf="155px"
            maxw400="95px"
          />
        </ContainerLabelHelp>



        <Label htmlFor="IM"> </Label>
        <GrupoInput>

          <Inpt
            type="text"
            id="IM"
            value={inversionMensual}
            onChange={(e) => {
              setInversionMensual(isNaN(e.target.value) ? "" : e.target.value)
              setIsValidInversionMensual(IsInversionMensual(e.target.value))
            }
            }
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />
        </GrupoInput>
        {(isValidInversionMensual) && (  
              <><IconoValidacion
                  icon={isValidInversionMensual ? faCheckCircle : faTimesCircle}
                  valido={isValidInversionMensual}
                />
             </>
          )}

        {(isValidInversionMensual === false) && (  
              <><IconoValidacion
                  icon={isValidInversionMensual ? faCheckCircle : faTimesCircle}
          
                  valido={isValidInversionMensual}
                />
              <Small>Valor debe ser igual a $0 o mayor a $500</Small>
              </>
          )}

        <Dis dis={displayTable} >
          <LabelSimulador mt="20px" lh="0px">
            ¿Conoces tu perfil?
          </LabelSimulador>
          <LabelSimulador mt="0px">
            Si no es así, realiza el siguiente cuestionario
          </LabelSimulador>
          <Button
            h="40px"
            m="10px 0px 10px 0px"
            bgck={!validate() ? "#E8E8E8" : "#004887"}
            color={!validate() ? "#616267" : "#FFFFFF"}
            disabled={!validate()}
            onClick={(e) => {
              e.preventDefault();
              navigate('/perfilador')
            }}
          >
            
            Tipo de perfil
          </Button>
        </Dis>
        <ContainerLabelHelp>
          {" "}
          <LabelSimulador>Nivel de riesgo </LabelSimulador>{" "}
          <Tooltip
            text="Dependiendo de qué tanto riesgo estés dispuesto a asumir con respecto a tus inversiones, podrías tener desde un perfil de riesgo moderado a uno agresivo."
           
            maxw="153px"
            maxw400="122px"
            lf="130px"

          />
        </ContainerLabelHelp>
        <RiesgoPrf>{inversionNivelRiesgo}</RiesgoPrf>
      
      {/* ---------------STEP PROGRESS BAR ----------------- */}
      
      {<Dis dis={displayTable}>
        <div className="step-progress-bar">
          <ProgressBar
            percent={per}
            width={175}
            height={2}
            hasStepZero={true}
            filledBackground="linear-gradient(to right, #00EFE8, #00EFE8)"
            unfilledBackground="#616267"
          >
            <Step >
              {({ accomplished, per }) => (
                <button className={`selectedStep ${accomplished ? "accomplished" : ""}`}
                  id="1"
                  style={{
                    width: 20,
                    height: 20,
                    borderRadius: 20,
                    background: "white",
                    border: "1px solid #616267",
                  }} onClick={handleClick}
                ></button>
              )}
            </Step>
            <Step>
              {({ accomplished }) => (
                <button className={`selectedStep ${accomplished ? "accomplished" : ""}`}
                  id="2"
                  style={{
                    width: 20,
                    height: 20,
                    borderRadius: 20,
                    background: "white",
                    border: "1px solid #616267",
                  }} onClick={handleClick}
                ></button>
              )}
            </Step>
            <Step>
              {({ accomplished }) => (
                <button className={`selectedStep ${accomplished ? "accomplished" : ""}`}
                  id="3"
                  style={{
                    width: 20,
                    height: 20,
                    borderRadius: 20,
                    background: "white",  
                    border: "1px solid #616267",
                  }}
                  onClick={handleClick}
                ></button>
              )}
            </Step>
            <Step >
              {({ accomplished }) => (
                <button className={`selectedStep ${accomplished ? "accomplished" : ""}`}
                  id="4"
                  style={{
                    width: 20,
                    height: 20,
                    borderRadius: 20,
                    background: "white",
                    border: "1px solid #616267",
                  }}
                  onClick={handleClick}
                ></button>
              )}
            </Step>
          </ProgressBar>

        </div>
        </Dis>
        }

        <Dis dis={displayRiskLevel}>
          {Perfil()}
        </Dis>
        <ContainerLabelHelp>
          <LabelSimulador>Duración del plazo </LabelSimulador>{" "}
          <Tooltip
            text="A mayor plazo, la rentabilidad suele ser superior."
            lf="159px"
            maxw400="90px"
          />
        </ContainerLabelHelp>

        <div className={classes.root}>
          <ConatainerInputAndSelect>
            <Input
              className={classes.input}
              value={duracionPlazo}
              onChange={handleInputChange}
              onBlur={handleBlur}
              inputProps={{
                step: 1,
                min: 0, 
                max: 70,
                type: "number",
              }}
            />

            <Select
              name="select"
              value={duracionTipo}
              onChange={(e) => {setDuracionTipo(e.target.value);}}
            >
              <option value="anios"> <p>años</p> </option>
              <option value="meses" selected>
              <p>  meses</p>
              </option>
            </Select>
          </ConatainerInputAndSelect>
          {!IsDuracionTipo(duracionTipo) && (
            <Small>Seleccione años o meses</Small>
          )}

          {(!IsDuracionPlazo(duracionPlazo,duracionTipo) || !isValidDuracionPlazo) && (
            <Small>El plazo mínimo es de 1 mes.<br/>Este valor no debe ser mayor a 60.</Small>
          )}

          <div style={{ marginTop: "20px" }}>
            <Slider
              value={typeof duracionPlazo === "number" ? duracionPlazo : 0}
              //min={12}
              max={50}
              onChange={handleSliderChange}
              aria-labelledby="input-slider"
              classes={{
                thumb: classes.thumb,
                rail: classes.rail,
                track: classes.track,
                mark: classes.mark,
                //
              }}
            />
          </div>
        </div>
        <Dis dis={displayCta}>
          <ContainerBox fd="column" mb="50px">
            <ContainerLabelHelp style={{height: "100px!important"}}>
              <LabelSimulador lh="1.3em">
                ¿Necesitas tener liquidez?
              </LabelSimulador>
              <Tooltip text="Si necesitas liquidez podrás disponer de un porcentaje de tu inversión durante el plazo seleccionado." w="158px" h="71px" lf="205px" />
            </ContainerLabelHelp>

            <ContainerBox fd="row" mt="5px">
              <ContainerIn>
                <div className="radio-liq">
                  <input
                    type="radio"
                    id="5"
                    value="Si"
                    checked={liquidez === "Si"}
                    onChange={(e) => {
                      setLiquidez(e.target.value);
                      IsInversionLiquidez(liquidez);
                    }}
                  />

                  <label htmLfor="5">Si</label>
                </div>

              </ContainerIn>
              <ContainerIn ml="60px">
                <div className="radio-liq">
                  <input
                    type="radio"
                    id="No"
                    value="No"
                    checked={liquidez === "No"}
                    onChange={(e) => {
                      setLiquidez(e.target.value);
                      IsInversionLiquidez(liquidez);
                    }}
                  />
                  <label htmLfor="6">No</label>
                </div>
              </ContainerIn>
            </ContainerBox>
            {!IsInversionLiquidez(liquidez) && <Small>Si o No </Small>}
          </ContainerBox>
        </Dis>
        <Dis dis={displayTable}>
          <RenderIf isTrue={showBtnAplicar} isFalse={null}>

            
            <Button
              h="40px" 
              disabled={!validateFormSimple() ? "disabled" : false }
              bgck={!validateFormSimple() ? "#E8E8E8" : "#004887"}
              color={!validateFormSimple() ? "#616267" : "#FFFFFF"}
              onClick={sendFormListener}
            >
              Aplicar
            </Button>
          </RenderIf>

          <RenderIf isTrue={showRes}>
             {  !loading
              ? <> 
            <Table
              capitalAcumuladoNeutral={capitalAcumuladoNeutral}
              rendimiento={rendimiento}
              capitalInvertido={ capitalInvertido }
              capitalAcumuladoMinimo={capitalAcumuladoMinimo}
              capitalAcumuladoMaximo={capitalAcumuladoMaximo}
            />
   </>
              : <div className="text-center h-100">
                  <ClipLoader color="#0091DA" loading={loading} size={100} />
                </div>
            }
            <Limpiar
              onClick={() => {
                setShowRes(false);
                setshowBtnAplicar(true);
                setPer(0);
                setInversionInicial("");
                setInversionMensual("");
                setInversionNivelRiesgo("Tradicional");
                setDuracionTipo("meses");
                setDuracionPlazo("");
                setCapitalAcumuladoNeutral(0)
                setCapitalAcumuladoMinimo(0)
                setCapitalAcumuladoMaximo(0)
              }}
            >
              Limpiar
            </Limpiar>
        
           
          </RenderIf>
        </Dis>

        <Dis dis={displayCta}>
          <Button
            h="40px"
            disabled={!validateFormSimple() ? "disabled" : false }
            bgck={!validateFormSimple() ? "#E8E8E8" : "#004887"}
            color={!validateFormSimple() ? "#616267" : "#FFFFFF"}
            onClick={sendFormListener}
          >
            Aplicar
          </Button>
        </Dis>
      </SubContainer>
    </SimuladorBox>
  );
};

export default Simulador;
