import React, {Fragment, useEffect} from 'react';
import SectionTwo from "./Sections/SectionTwo";
import SectionThree from "./Sections/SectionThree";
import SectionOne from './Sections/MainSection'
import SectionFourth from "./Sections/SectionFourth";

const LpFondos = ({
                    setCapitalAcumuladoNeutral,
                    capitalAcumuladoNeutral,
                    setCapitalAcumuladoMinimo,
                    capitalAcumuladoMinimo,
                    setCapitalAcumuladoMaximo,
                    capitalAcumuladoMaximo,
                    setCapitalInvertido,
                    capitalInvertido,
                    setRendimiento,
                    rendimiento,
                    inversionInicial,
                    setInversionInicial,
                    inversionMensual,
                    setInversionMensual,
                    inversionNivelRiesgo,
                    setInversionNivelRiesgo,
                    duracionTipo,
                    setDuracionTipo,
                    duracionPlazo,
                    setDuracionPlazo,
                    nivelPortafolio,
                setnivelPortafolio
                  }) => {
  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, []);
  return (
   <Fragment>
       <SectionOne
          inversionInicial={inversionInicial}
          setInversionInicial={setInversionInicial}
          inversionMensual={inversionMensual}
          setInversionMensual={setInversionMensual}
          inversionNivelRiesgo={inversionNivelRiesgo}
          setInversionNivelRiesgo={setInversionNivelRiesgo}
          setCapitalAcumuladoNeutral={setCapitalAcumuladoNeutral}
          capitalAcumuladoNeutral={capitalAcumuladoNeutral}
          setCapitalAcumuladoMinimo={setCapitalAcumuladoMinimo}
          capitalAcumuladoMinimo={capitalAcumuladoMinimo}
          setCapitalAcumuladoMaximo={setCapitalAcumuladoMaximo}
          capitalAcumuladoMaximo={capitalAcumuladoMaximo}
          setCapitalInvertido={setCapitalInvertido}
          capitalInvertido={capitalInvertido}
          setRendimiento={setRendimiento}
          rendimiento={rendimiento}
          duracionTipo={duracionTipo}
          setDuracionTipo={setDuracionTipo}
          duracionPlazo={duracionPlazo}
          setDuracionPlazo={setDuracionPlazo}
          nivelPortafolio={nivelPortafolio}
          setnivelPortafolio={setnivelPortafolio } 
           />
<SectionTwo />
       <SectionThree />
       <SectionFourth/>
  </Fragment> 
  )
}

export default LpFondos
