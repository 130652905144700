import Api from "../api";


// endpoints de principal
const HEALT = 'healt';
const AUTH = 'get-auth';
const SIMULADORCALC = 'simulador-calcular'
const SIMULADORCALCAPI = 'simulador-calcular-api'
const PERFILADOR = 'perfilador'
const QUESTIONS = 'catalogo-questions'
const FONDOS_LEAD = 'fondos-lead-source'


// const HEALT = 'healt';
// const SIMULADORCALC = 'fondos-simulador-calcular'
// const SIMULADORCALCAPI = 'fondos-simulador-calcular-api'
// const PERFILADOR = 'fondos-perfilador'
export default {
  Healt() {
    return Api.get(HEALT);
  },
  Questions() {
    return Api.get(QUESTIONS);
  },
  FondosLead(data) {
    return Api.post(FONDOS_LEAD,data);
  },
  Auth() {
    return Api.post(AUTH);
  },
  CalcularSimulador(data,config){
    return Api.post(SIMULADORCALC,data);
  },
  CalcularSimuladorApi(data,config){
    return Api.post(SIMULADORCALCAPI,data,config);
  },
  Perfilador(data,token){
    return Api.post(PERFILADOR,data);
  },
}

// configuracion para principal
/*
export default {
  Healt() {
    return Api.get(HEALT);
  },
  CalcularSimulador(data){
    return Api.post(SIMULADORCALC,data);
  },
  CalcularSimuladorApi(data, token){
    return Api.post(SIMULADORCALCAPI,data,{
      headers: {
        'Authorization': 'Bearer ' +  token
      }
    });
  },
  Perfilador(data,token){
    return Api.post(PERFILADOR,data,{
      headers: {
        'Authorization': 'Bearer ' +  token
      }
    });
  },
}*/