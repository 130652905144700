export const questions = [
    {   
        initialText:'¡Hola, que gusto verte por aquí!',
        question: '1. Es importante trazar un camino cuando se trata de invertir, por eso comencemos con tu objetivo:',
        answerOptions: [
            {question:1,order:1,id:1, value:'a', answerText: 'A) Comprar un coche', isCorrect: 1},
            {question:1,order:2,id:2, value:'b', answerText: 'B) Viajar', isCorrect: 1 },
            {question:1,order:3,id:3, value:'c', answerText: 'C) El enganche o hipoteca de mi casa', isCorrect: 1 },
            {question:1,order:4,id:4, value:'d', answerText: 'D) Tener un fondo para mi retiro', isCorrect: 1},
            {question:1,order:5,id:5, value:'e', answerText: 'E) Educación', isCorrect: 1},
            {question:1,order:6,id:6, value:'f', answerText: 'F) Crecer mi patrimonio', isCorrect: 1},
        ],
         
    },
    {
        initialText:'¡Excelente meta!',
        question: '2. ¿Alguna vez has invertido en Fondos de Inversión?',
        answerOptions: [
            {question:2,order:1,id:7 , value:'a2', answerText: 'A) No, estoy empezando.' , answerText2: 'Muchos términos financieros los desconozco y nunca he invertido.', isCorrect: 0 },
            {question:2,order:2,id:8 , value:'b2', answerText: 'B) Invierto desde hace un par de años.' , answerText2: 'Ya sé algo del tema, pero mi conocimiento sigue siendo básico.', isCorrect: 1 },
            {question:2,order:3,id:9 , value:'c2', answerText: 'C) Tengo más de 3 años de experiencia.' , answerText2: ' Con el tiempo he logrado comprender el tema, ahora conozco los beneficios y riesgos que implican.', isCorrect: 2 },
            {question:2,order:4,id:10, value:'d2', answerText: 'D) Con los años que llevo invirtiendo, me considero experto.' , answerText2: ' Domino por completo el tema y siento comodidad al momento de invertir.', isCorrect: 3 },
        ],

    },
    {
        initialText:'¡Muy bien! Ahora me gustaría que imaginarás esta situación:',
        question: '3. Invertiste $100,000 pesos, pero en los últimos dos meses has tenido pérdidas y de momento te quedaste con $82,000 pesos. ¿Qué harías?',
        answerOptions: [
            { question:3,order:1,id:11, value:'a3', answerText: 'A) Me cambio a un fondo menos arriesgado. ' , answerText2: ' Prefiero ir a un paso más lento, antes de seguir perdiendo más dinero.', isCorrect: -3 },
            { question:3,order:2,id:12, value:'b3', answerText: 'B) Diversificaría mi inversión.' , answerText2: ' Dejaría la mitad en el fondo actual y el resto lo movería a un fondo menos arriesgado.', isCorrect: 1 },
            { question:3,order:3,id:13, value:'c3', answerText: 'C) Me mantengo firme. ' , answerText2: 'Mi inversión es a largo plazo y es probable que me recuperé, una baja no me genera nervios.', isCorrect: 5 },
            { question:3,order:4,id:14, value:'d3', answerText: 'D) Invertiría más dinero en el mismo fondo. ' , answerText2: ' Con la baja del precio, compraría barato con el objetivo de recuperarme más rápido.', isCorrect: 15 },
        ],
    },
    {
        initialText:'¡Ahora veamos cómo manejas la presión de los negocios!',
        question: '4. Si tuvieras una inversión de $100,000 y tuvieras una pérdida de $5,000, selecciona la respuesta que mejor le describa.',
        answerOptions: [
            {question:4,order:1,id:15, value:'a4', answerText: 'A) Vendo inmediatamente y cambio el tipo de inversión.', isCorrect: -3 },
            {question:4,order:2,id:16, value:'b4', answerText: 'B) Esperaría 6 meses antes de cambiar de inversión.', isCorrect: 1 },
            {question:4,order:3,id:17, value:'c4', answerText: 'C) Esperaría 18 meses antes de cambiar de inversión.', isCorrect: 5 },
            {question:4,order:4,id:18, value:'d4', answerText: 'D) Esperaría entre 3 y 5 años antes de cambiar de inversión.', isCorrect: 15 },
        ],
    },
    {
        initialText:'¡Un buen inversionista sabe administrarse, veamos qué tal lo haces!',
        question: '5.¿Qué porcentaje de tus ingresos destinas para gastos?',
        answerOptions: [
            {question:5,order:1,id:19 , value:'a5',  answerText: 'A) Menos del 25%', isCorrect: 3 },
            {question:5,order:2,id:20 , value:'b5',  answerText: 'B) Entre 25% y 50%', isCorrect: 2 },
            {question:5,order:3,id:21 , value:'c5',  answerText: 'C) Entre 50% y 75%', isCorrect: 1 },
            {question:5,order:4,id:23 , value:'d5',  answerText: 'D) Más del 75%', isCorrect: 0 },
        ],
    },
];




export const result = {

tradicional: {
        perfil: "Tradicional",
        text:`¡Qué sorpresa!, este mismo resultado lo sacó Warren Buffett, el famoso genio inversionista, esperamos que seas igual de exitoso que él.`,
        description:`Los inversionistas con este perfil prefieren la tranquilidad de ver crecer su
        patrimonio de manera gradual, sin importar que los rendimientos sean bajos. No les gusta ver
        minusvalías en sus fondos de inversión y si viera disminuir el valor de su fondo lo más probable es que se
        cambiaria de producto de inversión de manera inmediata. Suele invertir en fondos de inversión de
        deuda a corto plazo`
    },
conservador: {

        perfil: "Conservador",
        text:`¡Qué sorpresa!, este mismo resultado lo sacó Warren Buffett, el famoso genio inversionista, esperamos que seas igual de exitoso que él.`,
        description:`Los inversionistas con este perfil tienen una tolerancia baja al riesgo, prefiere
        invertir en fondos de inversión con bajo riesgo sin importar que los rendimientos no sean muy elevados.
        Es capaz de soportar minusvalías por periodos máximos de medio año antes de cambiar parte de su
        patrimonio invertido en otro fondo o portafolio.`
    
    },
moderado:	{
        perfil: "Moderado",
        text:`¡Qué sorpresa!, este mismo resultado lo sacó Warren Buffett, el famoso genio inversionista, esperamos que seas igual de exitoso que él.`,
        description:`A los inversionistas con este perfil no les importa ver disminuciones en el saldo de su
        patrimonio provocado por minusvalías en sus fondos de inversión, son firmes al mantener sus
        posiciones en la misma inversión cuando el mercado está a la baja, están dispuestos a esperar más de
        un año y medio para recuperarse de las posibles minusvalías provocadas por la volatilidad de los
        mercados. Buscan balance entre rendimientos y nivel de riesgo del portafolio. Suele buscar un portafolio
        o cartera de inversión que combine de forma balanceada instrumentos a mediano y largo plazo, tanto
        de deuda como de renta variable.`
    },
agresivo:{
        perfil: "Agresivo",
        text:`¡Qué sorpresa!, este mismo resultado lo sacó Warren Buffett, el famoso genio inversionista, esperamos que seas igual de exitoso que él.`,
        description:`A los inversionistas con este perfil no les importa ver disminución en el saldo de su
        patrimonio provocado por la volatilidad, toman como una oportunidad de compra cuando el mercado
        está a la baja. Están dispuestos a esperar años para recuperarse de las posibles minusvalías. Buscan altos
        rendimientos de largo plazo. Este inversionista prefiere portafolios de inversión en los que se combinen
        mayormente instrumentos en renta variable y de forma complementaria, deuda a largo plazo.`
    },
    
}