import React from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Row } from "react-bootstrap";
import { Button } from "./../assets/styles/StylesGeneric.styled";
import RowR from "./../assets/icons/rowr.svg";
import { ContainerBtn } from "./../assets/styles/Perfilador.styled";
import ClipLoader from "react-spinners/ClipLoader";

const useStyles = makeStyles((theme) => ({
  section: {
    maxWidth: "54rem",
    margin: "30px auto 20px  auto",
    backgroundColor: "#ffffff",
    padding: "0 10px",
    "@media (max-width: 800px)": {
      padding: "0 30px",
    },
  },
  title: {
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "center",
    alignItems: "center",
    fontSize: "30px",
  },
  subtitle: {
    fontSize: "22px",
    marginBottom: "15px",
  },
  paragraph: {
    fontSize: "16px",
    marginTop: "10px",
    "@media (max-width: 768px)": {
      fontSize: "24px",
    },
  },
  box: {
    border: "solid",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
    borderRadius: "10px 0 0 0",
    borderColor: "#0076CF",
    borderWidth: "0 0 0 75px",
    paddingTop: "10px",
    marginBottom: "20px",
    width: "97%",
  },
  justCont: {
    justifyContent: "center",
  },
  hideOnMovil: {
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
}));

export default function SectionTwo({ onReset, text, perfil, description }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const isLoading = !perfil && !description;

  return (
    <div className={classes.section}>
      {!isLoading ? (
        <>
          <Row>
            <div className={classes.title}>
              <span>
                {" "}
                Tu perfil es <b>{perfil.toLowerCase()}</b>{" "}
              </span>
            </div>
          </Row>
          <Row>
            <div className={classes.paragraph}>
              <p>{text}</p>
            </div>
          </Row>
          <Row className={classes.subtitle}>
            <span>
              <b>{perfil} </b>
            </span>
          </Row>

          <Row className={classes.justCont}>
            <div className={classes.box}>
              <p>{description}</p>
            </div>
          </Row>
          <Row className={[classes.paragraph, classes.hideOnMovil]}>
            <p>
              De acuerdo a tus respuestas hemos calculado que tu perfil ideal es{" "}
              {perfil.toLowerCase()}, si estás de acuerdo con esto da clic en
              avanzar o si lo deseas puedes volver a repetir las preguntas.
            </p>
          </Row>
          <ContainerBtn>
            <Button
              Color="#0061A0"
              w="192px"
              bgck="#ffff"
              color="#0061A0"
              bder=" 1px solid #0061A0"
              HoverbackgroundColor="none"
              onClick={onReset}
            >
              Inténtalo de nuevo
            </Button>

            <Button
              w="158px"
              ml="20px"
              onClick={(e) => {
                e.preventDefault();
                navigate("/simulador");
              }}
            >
              Avanzar{" "}
              <img style={{ marginLeft: "10px" }} src={RowR} alt="RowR" />{" "}
            </Button>
          </ContainerBtn>
        </>
      ) : (
        <div className="m-5 p-5 text-center h-100">
          <ClipLoader color="#0091DA" loading={isLoading} size={100} />
        </div>
      )}
    </div>
  );
}
