import React, { useState } from "react";
import HelpIcon from "./../../assets/icons/HelpIcon.svg";
import { HelpIconSim } from "./../../assets/styles/Simulador.styled";
import { ToolTipContainer , HoverableContainer,ToolTipText} from "./../../assets/styles/Simulador.styled";


const HoverableDiv = ({ handleMouseOver, handleMouseOut }) => {
  return (
    <HoverableContainer onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        <HelpIconSim src={HelpIcon} alt="warn" />
    </HoverableContainer>
  );
};

const HoverText = ({ text, lf , btom, w, widText, h, maxw, maxw400}) => {
  return (
    <ToolTipContainer lf={lf} btom={btom} w={w} h={h} maxw400={maxw400} maxw={maxw}>
        <ToolTipText w={widText}>
          {text}
        </ToolTipText >
     </ToolTipContainer >
  );
};

const ToolTip = ({ text, lf , btom, w, widText, h, maxw, maxw400 }) => {
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <>
      <HoverableDiv
        handleMouseOver={handleMouseOver}
        handleMouseOut={handleMouseOut}
      />
      {isHovering && <HoverText 
      text={text}
      lf={lf}
      btom={btom}
      w={w}
      widText={widText}
      h={h}
      maxw={maxw}
      maxw400={maxw400}
      />}
    </>
  );
};

export default ToolTip;
