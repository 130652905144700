import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Row, Col } from "react-bootstrap";
import imageSrc from "../../assets/images/section-three/ImgSec3.svg";
import {Button} from '../../assets/styles/StylesGeneric.styled';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  section: {
    maxWidth: '56rem',
    margin: "70px auto 80px  auto",
    backgroundColor: "#ffffff",
    padding: '0 30px 0 30px',
    paddingRight: '42px',
    '@media (max-width: 500px)': {
      padding: '0 15px 0 0px',
    }
  },
  title: {
    display: "flex",
    justifyContent: "center",
    height: "50px",
    alignContent: "center",
    alignItems: "center",
    fontWeight: "700",
    fontSize: "28px",
    fontFamily:'eliotprobold',
    '@media (max-width: 768px)': {
      fontWeight: '400',
      fontSize: '21px'
    },
    '@media (max-width: 500px)': {
      fontWeight: '400',
      fontSize: '18px',
      width: '46%',
      textAlign: 'center',
      fontFamily:'eliotpro',
    }
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: '100%',
  },
  listContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "15px",
    height: '100%'
  },
  images: {
    width:'80%',   
  },
  hideOnMovil:{
    '@media (max-width: 768px)': {
      display: 'none'
    }
  },
  listRow: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    paddingBottom: '15px'
  },
  item: {
    marginLeft: "15px",
  },
  itemTitle: {
    fontWeight: "700",
    fontSize: "18px",
    color: "#333333 ",
  },
  itemContent: {
    fontWeight: "400",
    fontSize: "18px",
  },
  expansionLine:{
    borderTop: 'solid 3px #0061A0;',
    width: '22%',
    borderRadius: '30px',
    margin: '25px 0 10px 0'
  },
  justCont: {
    justifyContent: "center",
  },
  btnWrapper:{
    width: '100%',
    display: 'flex',
    marginTop: '30px',
    justifyContent: 'center' 
  }
}));

export default function SectionTwo() {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <div className={classes.section}>
      
        <Row className={classes.justCont}>
          <div className={classes.title}>
            <span>¿Qué inversión es valiosa para ti? </span>
          </div>
        </Row>
        <Row className={classes.justCont}>
          <Col md={7} className={classes.hideOnMovil}>
            <div className={classes.imageContainer}>
              <img className={classes.images} src={imageSrc}></img>
            </div>
          </Col>
          <Col  md={5} sm={9} xs={12} >
            <div className={classes.listContainer}>
              <Row>
                <div className={classes.listRow}>
                  <Col>
                  
                    <div className={classes.item}>
                    <div className={classes.expansionLine}></div>
                      <div className={classes.itemTitle}>
                        Invierte en tus seres queridos
                      </div>
                      <div className={classes.itemContent}>
                        Construye el patrimonio de tu familia con el respaldo de
                        una empresa global.
                      </div>
                    </div>
                  </Col>
                </div>
              </Row>
              <Row>
                <div className={classes.listRow}>
                  <Col>
                 
                    <div className={classes.item}>
                    <div className={classes.expansionLine}></div>
                      <div className={classes.itemTitle}>
                        Viajar, comprar un auto o seguir estudiando
                      </div>
                      <div className={classes.itemContent}>
                        Invierte a tu ritmo y alcanza tu meta a corto, mediano o
                        largo plazo, siempre de la mano de expertos.
                      </div>
                    </div>
                  </Col>
                </div>
              </Row>

              <Row>
                <div className={classes.listRow}>
                  <Col>
                  
                    <div className={classes.item}>
                    <div className={classes.expansionLine}></div>
                      <div className={classes.itemTitle}>
                        Retirarme como los grandes
                      </div>
                      <div className={classes.itemContent}>
                        Tu dinero crecerá y tendrás la seguridad que estará para
                        ti el día de tu jubilación.
                      </div>
                    </div>
                  </Col>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
        <div className={classes.btnWrapper}>
        <Button  
          type="button"
          onClick={(e) => {
            e.preventDefault();
            navigate('/perfilador')
          }}
        >Quiero invertir</Button>
        </div>
    </div>
  );
}