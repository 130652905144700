import React from 'react';
import {
  ContainerSection,
  ItemSection,
  Button,
  Paragraph14,
  MainTitle,
  Paragraph24,
  ContainerItems,
  ItemWrapper,
  ItemWrapperMob
} from '../../assets/styles/StylesGeneric.styled';
import './../../assets/styles/StylesGeneric.css';
import Simulador from '../components/Simulador';
import { useNavigate } from "react-router-dom";

const MainSection = ({ setCapitalAcumuladoNeutral,
  capitalAcumuladoNeutral,
  setCapitalAcumuladoMinimo,
  capitalAcumuladoMinimo,
  setCapitalAcumuladoMaximo,
  capitalAcumuladoMaximo,
  setCapitalInvertido,
  capitalInvertido,
  setRendimiento,
  rendimiento,
  inversionInicial,
  setInversionInicial,
  inversionMensual,
  setInversionMensual,
  inversionNivelRiesgo,
  setInversionNivelRiesgo,
  duracionTipo,
  setDuracionTipo,
  duracionPlazo,
  setDuracionPlazo,
  nivelPortafolio,
  setnivelPortafolio,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <ContainerSection className='mainSection' mh="95vh" maxh900=" ">
        <ContainerItems m="auto" pad1300="35px 30px 35px 0" pad="35px 0" pad900="30px 0">
          <ItemWrapper w="50%" pad="0 0 0 12%" pad1250="0 0 0 9%" pad1000="0 0 0 3%">
            <ItemSection m320Mob="60px 0px 0px 5%" w425="89%" pad425="0 30px 0 0 " >
              <MainTitle> Invierte en lo que realmente vale...</MainTitle >
              <Paragraph24 hMob="140px" mb320Mob="40px" mb="30px">El mundo cambió, ahora descubrimos que lo más valioso en la vida es invertir en salud, conocimiento, tu pasión, los seres que amas... </Paragraph24>
              <Paragraph24 mbMob="24px" h="40px" mb="76px" hMob="40px" fw="700" fwMob="400" mb320Mob="40px" >¿Tú en qué estás invirtiendo? </Paragraph24>
              <Paragraph14>Con un Fondo de inversión llega a tu meta, desde $1,000 pesos</Paragraph14>
              <Button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/perfilador')
                }}

                m="0px 0px 0px 120px"
                hMob="48px"
                mMob="0"
                als="center"

              >Quiero invertir</Button>
            </ItemSection>
          </ItemWrapper>
          <ItemWrapper w="50%" jc="flex-end" dis800="none">
            <ItemSection disMob="none" >
              <Simulador
                displayCta="none"
                displayRiskLevel="none"
                className="simulador"
                setCapitalAcumuladoNeutral={setCapitalAcumuladoNeutral}
                capitalAcumuladoNeutral={capitalAcumuladoNeutral}
                setCapitalAcumuladoMinimo={setCapitalAcumuladoMinimo}
                capitalAcumuladoMinimo={capitalAcumuladoMinimo}
                setCapitalAcumuladoMaximo={setCapitalAcumuladoMaximo}
                capitalAcumuladoMaximo={capitalAcumuladoMaximo}
                setCapitalInvertido={setCapitalInvertido}
                capitalInvertido={capitalInvertido}
                setRendimiento={setRendimiento}
                rendimiento={rendimiento}
                inversionInicial={inversionInicial}
                setInversionInicial={setInversionInicial}
                inversionMensual={inversionMensual}
                setInversionMensual={setInversionMensual}
                inversionNivelRiesgo={inversionNivelRiesgo}
                setInversionNivelRiesgo={setInversionNivelRiesgo}
                duracionTipo={duracionTipo}
                setDuracionTipo={setDuracionTipo}
                duracionPlazo={duracionPlazo}
                setDuracionPlazo={setDuracionPlazo}
                nivelPortafolio={nivelPortafolio}
                setnivelPortafolio={setnivelPortafolio}
              />
            </ItemSection>
          </ItemWrapper>
        </ContainerItems>

      </ContainerSection>
      <ItemWrapperMob dis="none" >
        <ItemSection m="30px 0px 0px 0px" w425="100%" w800="100%">
          <Simulador
            displayCta="none"
            displayRiskLevel="none"
            className="simulador"
            setCapitalAcumuladoNeutral={setCapitalAcumuladoNeutral}
            capitalAcumuladoNeutral={capitalAcumuladoNeutral}
            setCapitalAcumuladoMinimo={setCapitalAcumuladoMinimo}
            capitalAcumuladoMinimo={capitalAcumuladoMinimo}
            setCapitalAcumuladoMaximo={setCapitalAcumuladoMaximo}
            capitalAcumuladoMaximo={capitalAcumuladoMaximo}
            setCapitalInvertido={setCapitalInvertido}
            capitalInvertido={capitalInvertido}
            setRendimiento={setRendimiento}
            rendimiento={rendimiento}
            inversionInicial={inversionInicial}
            setInversionInicial={setInversionInicial}
            inversionMensual={inversionMensual}
            setInversionMensual={setInversionMensual}
            inversionNivelRiesgo={inversionNivelRiesgo}
            setInversionNivelRiesgo={setInversionNivelRiesgo}
            duracionTipo={duracionTipo}
            setDuracionTipo={setDuracionTipo}
            duracionPlazo={duracionPlazo}
            setDuracionPlazo={setDuracionPlazo}
            nivelPortafolio={nivelPortafolio}
            setnivelPortafolio={setnivelPortafolio}
          />
        </ItemSection>
      </ItemWrapperMob>

    </>
  )
}

export default MainSection;