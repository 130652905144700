import styled from 'styled-components';

export const PerfiladorContainer = styled.div`
display: flex;
min-height: 620px;
justify-content: center ;
width:100% ;
@media (max-width: 700px) {
  min-height: 700px;
  overflow-x: hidden !important;
}

@media (max-width: 500px) {
  min-height: 600px;
}
`
export const ContainerQuestion = styled.div`
 display: flex ;
 flex-direction: column;
 width:${(props) => props.w || ' '};  ;
 margin-top: 60px ;
 padding-left: 5% ;
 @media (max-width: 800px) {
  padding-left: 0 ;
  
}
@media (max-width: 450px) {
  padding-left: 0 ;
  
}
`

export const ContainerOption = styled.div`
 display:  ${(props) => props.dis || 'flex'};
 align-items: ${(props) => props.at || ' '}; 
 margin-bottom: ${(props) => props.mb || ' '}; 
  padding-left: 4% ;
`
export const ContainerBtn = styled.div`
 margin:${(props) => props.mt || '49px 0'};
 display: flex ;
 width:100% ;
justify-content: center;
@media (max-width: 800px) {
  
}
`

export const Prf = styled.p`
width:${(props) => props.w || '570px'}; 

font-family: 'eliotpro';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #616267;
margin-left:${(props) => props.ml|| ''};  
text-align:start ;

margin-bottom:${(props) => props.mb|| '40px'};
@media (max-width: 800px) {
  max-width: ${(props) => props.mwMob || ' '};
  width: ${(props) => props.wMob || ' '};
}
@media (max-width: 600px) {
  margin:0 auto 40px auto;

  font-size: 24px;
}
`
export const Question = styled.p`

font-family: 'eliotpro';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 20px;
color: #333333;
text-align:start ;
margin-bottom:30px;
padding-left:5px;
@media (max-width: 400px) {
  width: ${(props) => props.wMob || ' '};
  max-width: 303px;
  margin-bottom:40px;
}
`

export const BtnPrevNext = styled.button`
border: 1px solid #0061A0;
border-radius: 25px;
transform: matrix(1, 0, 0, -1, 0, 0);
`

export const  Title = styled.p`
max-width: ${(props) => props.mb|| '55%'};
font-family: 'eliotprolight';
font-style: normal;
font-weight: 300;
font-size: 40px;
line-height: 44px;
text-align: ${(props) => props.tal || 'center'}; 
color: #FFFFFF;
margin-top: 160px ;
margin-left:${(props) => props.ml || ' '}; 
@media (max-width: 800px) {
  max-width: 337px;
  width: ${(props) => props.wMob || ''};
  text-align: center;
  margin-top: 60px ;
  margin-left:${(props) => props.ml || ' '}; 
} 
`

export const CardModal = styled.div`
width: 372px;
height: 252px;
display: flex;
flex-direction: column;
justify-content:center ;
align-items: center;
background: #FFFFFF;
border-radius: 5px;
@media (max-width: 425px) {
   width: 300px;
   
   
  }
`;

export const ParfModal = styled.p`
width: 273px;
height: 89px;
font-family: 'eliotpro';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 18px;
margin-bottom: 20px;
color: #333333;
@media (max-width: 425px) {
  width: 250px;
  
  
 }
`;

export const Merror = styled.p`
width: 294px;
height: 19px;
font-family: 'eliotpro';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
color:#C00000;
margin-left:150px;
@media (max-width: 700px) {
  margin-left:30px;
}
`;

export const Label = styled.label`
width:${(props) => props.w || ' '}; 
max-width:${(props) => props.maxw || ' '}; 
font-family: 'eliotpro';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 18px;
color: #616267;
margin-left:${(props) => props.ml|| ''};  
padding-left:${(props) => props.pl|| ''};  
text-align:start ;
margin-bottom:${(props) => props.mb|| '10px'};
@media (max-width: 800px) {
  max-width: ${(props) => props.mwMob || ' '};
  width: ${(props) => props.wMob || ' '};
}
@media (max-width: 600px) {
  margin-left:${(props) => props.ml600|| ''}; 
}
`;

export const Btnlink = styled.button`
  
  font-family: "eliotpro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #0061a0;
  margin-top: 10px;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  border-bottom: 2px dotted #0061a0;
  margin-left: 65px;
`;