import React from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from '../../assets/styles/StylesGeneric.styled';

const useStyles = makeStyles((theme) => ({
    outerWrapper: {
        width: '100%',
        backgroundColor: "#F0FBFE",
        display: 'block',
        justifyContent: 'center',
        alignContent: 'center',
        '@media (max-width: 768px)': {
            display: 'flex',
            flexDirection: 'column'
        },
        '& table': {
            borderCollapse: 'collapse',
        },
        '& table td, table th': {
            border: '2px solid #F6F6F8',
        },
        '& table tr:first-child th': {
            borderTop: '0'
        },
        '& table tr:last-child td': {
            borderBottom: '0'
        },
        '& table tr td:first-child, table tr th:first-child': {
            borderLeft: '0',
            borderTop: '0'
        },
        '& table tr td:last-child, table tr th:last-child': {
            borderRight: '0'
        }
    },
    row: {
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        width: "100%"
    },
    tableCont: {
        width: "120%",
        transform: "translateX(-18px)",
        margin: "0 auto 20px auto",
        fontSize: "12px",
        backgroundColor: "#FFFFFF",
    },
    text: {
        justifyContent: "center",
        display: "flex",
        margin: "10px 0px"
    },
    t1: {
        color: "#004C97",
        fontSize: "28px",
        fontFamily: 'eliotprobold',

    },
    t2: {
        fontWeight: "700",
        textAlign: "center",
    },
    itemWrapper: {
        margin: "10px 0"
    },
    fonttable2: {
        fontSize: "14px",
        color: "#616267"
    }


}));

export default function Table({ dis,rendimiento, capitalAcumuladoNeutral,capitalInvertido, capitalAcumuladoMinimo, capitalAcumuladoMaximo }) {
    const classes = useStyles();

    const optionsMoneyFormat = { style: 'currency', currency: 'MXN' }
    const numberFormat = new Intl.NumberFormat('es-MX', optionsMoneyFormat)


    const navigate = useNavigate();

    return (

        <div className={classes.outerWrapper}>
            <table className={classes.tableCont}>

                <tr>
                    <td colspan="2">
                        <div className={classes.itemWrapper}>

                            <div className={classes.row + ' ' + classes.t1}>
                                {capitalAcumuladoNeutral > 0 ? numberFormat.format(capitalAcumuladoNeutral): " - "}
                            </div>
                            <div className={classes.row}>
                                Monto estimado*
                            </div>
                        </div>

                    </td>
                </tr>

                <tr className={classes.fonttable2}>

                    <td colspan="1" width="50%" >
                        <div className={classes.itemWrapper}>
                            <div className={classes.row}>
                                { // numberFormat.format(capitalAcumuladoMinimo)
                                rendimiento > 0 ? numberFormat.format(rendimiento): " - "}
                            </div>
                            <div className={classes.row + ' ' + classes.t2}>
                                Rendimiento
                            </div>
                        </div>

                    </td>

                    <td colspan="1" width="50%">
                        <div className={classes.itemWrapper}>
                            <div className={classes.row}>
                                {// numberFormat.format(capitalAcumuladoMaximo)
                                capitalInvertido > 0 ? numberFormat.format(capitalInvertido): " - "}
                            </div>
                            <div className={classes.row + ' ' + classes.t2}>
                                Capital invertido
                            </div>
                        </div>

                    </td>

                </tr>

            </table>

            <div className={classes.text}>
                ¿Te gustó lo que viste?
            </div>


            <Button h="32px"
                w="190px"

                onClick={(e) => {
                    e.preventDefault();
                    //window.location.href ='/perfilador';
                    navigate('/perfilador')
                }}
            >Da el siguiente paso  </Button>

        </div>

    );
}
