import React, { useState} from "react";
import {
  PerfiladorContainer,
  Title,
  CardModal,
  ParfModal,
} from "../assets/styles/Perfilador.styled";
import {
  ContainerSection,
  Button,
} from "../assets/styles/StylesGeneric.styled";
import Question from "./Questions";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";


function getModalStyle() {
  const top = 48;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    border: "none",
    "&:focus": {
      outline: "none"
    },
  },

}));

const Perfilador = ({
  inversionNivelRiesgo,
  setInversionNivelRiesgo,
  setIsPassingForPerfiliador
}) => {
 const [modalStyle] = useState(getModalStyle);
 const [open, setOpen] = useState(true);
 const classes = useStyles();
 
 let isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
 navigator.userAgent &&
 navigator.userAgent.indexOf('CriOS') == -1 &&
 navigator.userAgent.indexOf('FxiOS') == -1;

  const handleClose = () => {
    setOpen(false);
  };

  const ModalPerfilador = () => {
    return (
      <div>
        <Modal
          open={open}
        
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.paper}>
            <CardModal>
              <ParfModal>
              <b>Queremos conocerte mejor</b><br/>
                ¡Para ofrecerte una opción de inversión personalizada, queremos{" "}
                <b>conocerte mejor</b>, ayúdanos respondiendo estas 5 preguntas!
              </ParfModal>
              <Button bgck="#0076CF" onClick={handleClose}>
                ¡Entendido!
              </Button>
            </CardModal>
          </div>
        </Modal>
      </div>
    );
  };

  return (
    <>
      <ContainerSection className="mainSectionPerfilador" h="280.08px">
        <Title ml="0" tal="left">¿Qué tipo de inversionista eres? </Title>
      </ContainerSection>
      <PerfiladorContainer>
    
       <Question 
        isSafari={isSafari}
        inversionNivelRiesgo={inversionNivelRiesgo}
        setInversionNivelRiesgo={setInversionNivelRiesgo}
        setIsPassingForPerfiliador={setIsPassingForPerfiliador}
       />
      
     

     {open ? <ModalPerfilador /> : null}
      </PerfiladorContainer>
    </>
  );
};

export default Perfilador;
