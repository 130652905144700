/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Row, Col } from "react-bootstrap";
import two from "./../../assets/images/section-fourth/2.svg";
import three from "./../../assets/images/section-fourth/3.svg";
import four from "./../../assets/images/section-fourth/4.svg";
import five from "./../../assets/images/section-fourth/5.svg";
import six from "./../../assets/images/section-fourth/6.svg";
import seven from "./../../assets/images/section-fourth/7.svg";

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    width: "100%",
    backgroundColor: "#F0FBFE",
  },
  section: {
    maxWidth: "47rem",
    margin: "30px auto 0px  auto",
    backgroundColor: "#F0FBFE",
    padding: "0 10px 50px 10px",
    minHeight: "600px",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    paddingTop: "30px",
  },
  title: {
    display: "flex",
    alignItems: "center",
    fontWeight: "700",
    fontSize: "28px",
    padding: "60px 130px 20px",
    textAlign: "center",
    fontFamily: "eliotprobold",
    "@media (max-width: 768px)": {
      fontWeight: "400",
      fontSize: "21px",
    },
    "@media (max-width: 500px)": {
      fontSize: "18px",
      padding: "40px 80px",
      fontFamily: "eliotpro",
    },
  },
  item: {
    marginLeft: "15px",
  },

  itemContent: {
    fontWeight: "400",
    fontSize: "18px",
  },

  justCont: {
    justifyContent: "center",
    textAlign: "center",
    alignItems: "flex-start",
    //    cambio para infp
    // '@media (max-width: 768px)': {
    //     width: '90%',
    //     margin: 'auto'
    //   }
  },
  colAlign: {
    padding: "35px 50px",

    "@media (max-width: 500px)": {
      padding: "35px 10px",
    },
  },
  img: {
    minHeight: "90px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
}));

export default function SectionTwo() {
  const classes = useStyles();
  return (
    <div className={classes.outerWrapper}>
      <div className={classes.section}>
        <Row>
          <div className={classes.title}>
            <span>
              ¡Mejor pon tu ahorro en un Fondo de Inversión que lo haga crecer!{" "}
            </span>
          </div>
        </Row>
        <Row className={classes.justCont}>
          <Col md={4} xs={6} className={classes.colAlign}>
            <div className={classes.img}>
              <img src={two}></img>
            </div>
            <div className={classes.text}>
              <div className={classes.itemText}>
                Tu inversión es profesional al ser{" "}
                <b>administrada por expertos</b>
              </div>
            </div>
          </Col>

          <Col md={4} xs={6} className={classes.colAlign}>
            <div className={classes.img}>
              <img src={three}></img>
            </div>
            <div className={classes.text}>
              <div className={classes.itemText}>
                <b>Diversificamos tu cartera</b> para optimizar tus ganancias{" "}
              </div>
            </div>
          </Col>

          <Col md={4} xs={6} className={classes.colAlign}>
            <div className={classes.img}>
              <img src={four}></img>
            </div>
            <div className={classes.text}>
              <div className={classes.itemText}>
                <b>Seguridad</b> en cada una de tus operaciones
              </div>
            </div>
          </Col>

          <Col md={4} xs={6} className={classes.colAlign}>
            <div className={classes.img}>
              <img src={five}></img>
            </div>
            <div className={classes.text}>
              <div className={classes.itemText}>
                Te damos acceso a <b>mercados globales</b>
              </div>
            </div>
          </Col>

          <Col md={4} xs={6} className={classes.colAlign}>
            <div className={classes.img}>
              <img src={six}></img>
            </div>
            <div className={classes.text}>
              <div className={classes.itemText}>
                <b>Sin plazos forzosos,</b> retira tu dinero en el momento que
                quieras{" "}
              </div>
            </div>
          </Col>

          <Col md={4} xs={6} className={classes.colAlign}>
            <div className={classes.img}>
              <img src={seven}></img>
            </div>
            <div className={classes.text}>
              <div className={classes.itemText}>
                <b>Sin penalizaciones ocultas,</b> no tenemos letras chiquitas
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
