import Api from "../apiAuth";

const TOKEN = 'oauth2/token';
const params = new URLSearchParams();
params.append('grant_type', 'client_credentials');
params.append('client_id', '6s0tqh0a4cv9t37qhvcs29m51h');
params.append('client_secret', 'gdcvit0mhgs2rlove1cvmdjq9e8tc6uqmprvtut3sa558e2mgd3');

export default {
  GetToken() {
    return Api.post(TOKEN,params);
  }
}