import styled from 'styled-components';

export const ContainerSection = styled.div`
display:flex ;
flex-direction: column;
width:100%;
height:${(props) => props.h || ' '}  ;
min-height:${(props) => props.mh || ' '}  ;
margin-left:${(props) => props.left || ' '};
justify-content:center ;
background:${(props) => props.bgck || ' '} ;
opacity:${(props) => props.op || ' '}  ;
@media (max-width: 900px) {
  height:${(props) => props.h900 || 'unset '}  ;
  width:${(props) => props.wMob || '100%'}  ;
  min-height:${(props) => props.minh900 || '230px'}  ;
  max-height:${(props) => props.maxh900 || ' 95vh'}  ;
}
}
@media (max-width: 700px) {
 
  min-height:${(props) => props.minh700 || '  '}  ;
}
@media (max-width: 500px) {
 
  min-height:${(props) => props.minh500 || '  '}  ;
}
`


export const ContainerItems = styled.div`
display:${(props) => props.dis || ' flex '} ;
flex-direction:${(props) => props.dis || ' '} ; 
width:${(props) => props.w || '100%'}   ;
height:${(props) => props.h || ' '}  ;
margin:${(props) => props.m || ' '};
padding:${(props) => props.pad || ' '};
margin-left:${(props) => props.left || ' '};
background:${(props) => props.bgck || ''} ;
opacity:${(props) => props.op || ' '} ; 
align-items:${(props) => props.ai || 'center'};
justify-content:${(props) => props.jc || 'center'};
max-width: ${(props) => props.mw || '81rem;'};
border:${(props) => props.b || ' '};
border-radius:${(props) => props.br || ' '};
@media (max-width: 1300px) {
  
  padding:${(props) => props.pad1300 || ' '};

}
@media (max-width: 900px) {
  flex-direction:${(props) => props.disMob || ' '} ;
  border:${(props) => props.b900 || ' '};
  padding:${(props) => props.pad900 || ' '};
  height:${(props) => props.h900 || ''}  ;
  margin:${(props) => props.m900 || ''};
}

@media (max-width: 800px) {
  
  padding:${(props) => props.pad800 || ' '};
  /* font-size:30px */
}
`;


export const ItemWrapper = styled.div`
display:${(props) => props.dis || ' flex '} ;
width:${(props) => props.w || '100%'}   ;
justify-content:${(props) => props.jc || 'center'};
padding:${(props) => props.pad || ' '};
flex-wrap: ${(props) => props.wrap || ' '};
border:${(props) => props.b || ' '};
border-radius:${(props) => props.br || ' '};
height: 100%;
background-color:${(props) => props.bgcolor || ' '};
@media (max-width: 1250px) {
  padding:${(props) => props.pad1250 || ' '};
  
}  
@media (max-width: 900px) {
  display:${(props) => props.dis900 || ' flex '} ;
  padding:${(props) => props.pad1000 || ' '};
  border-radius:${(props) => props.br900 || ' '};
  margin: ${(props) => props.m900 || ' '};
  width:${(props) => props.w900 || ' '}   ;
}  

@media (max-width: 800px ){
  display:${(props) => props.dis800 || ' '} ;
  width:${(props) => props.w800 || '100%'}   ;
  padding: ${(props) => props.pad800 || 'unset'} ; 
}
`;



export const ItemWrapperMob = styled.div`
display:${(props) => props.dis || ' flex '} ;
width:${(props) => props.w || '100%'}   ;
justify-content:${(props) => props.jc || 'center'};
padding:${(props) => props.pad || ' '};

@media (max-width: 800px) {
  display:${(props) => props.dis800 || ' flex '} ;
}  

`;



export const ItemSection = styled.div`
display:${(props) => props.dis || ' flex '} ;
flex-direction:${(props) => props.dis || 'column'} ; 
width:${(props) => props.w || ' '}   ;
height:${(props) => props.h || ' '}  ;
margin-left:${(props) => props.left || ' '};
background:${(props) => props.bgck || ''} ;
opacity:${(props) => props.op || ' '}  ;
margin: ${(props) => props.m || ' '};
@media (max-width: 800px) {
  display: ${(props) => props.disMob || 'flex'};
  margin: ${(props) => props.mMob || ' '};
    width:${(props) => props.w800 || '80%'};
}

 @media (max-width: 425px) {
  display: ${(props) => props.disMob || 'flex'};
  margin: ${(props) => props.m425Mob || '  '};
   justify-content: center ;
    width: ${(props) => props.w425 || '75%'};
    padding: ${(props) => props.pad425 || ' '};
}

@media (max-width: 390px) {
  display: ${(props) => props.disMob || 'flex'};
  margin: ${(props) => props.m425Mob || '  '};
}
@media (max-width: 320px) {
  display: ${(props) => props.disMob || 'flex'};
  margin: ${(props) => props.m320Mob || '  '};
    padding-left:0;
    padding-right:0;
} 
`;



export const MainTitle = styled.h1`
font-family: 'eliotpro';
font-style: normal;
font-weight: 400;
font-size: 45px;
line-height: 50px;
color: #FFFFFF;
margin-bottom: 37px;

@media (max-width: 700px) {
max-width: 328px;
height: 58px;
font-size: 30px;
line-height: 33px;
color: #FFFFFF;
margin-bottom: 50px;
}
@media (max-width: 320px) {
  max-width: 290px;
  margin-bottom:30px ;
}

`;

export const Paragraph14 = styled.p`

font-family: 'eliotprobold';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 15px;
color: #FFFFFF;
margin-bottom: 37px;
@media (max-width: 800px) {
width: ${(props) => props.wMob || ' '};
height: ${(props) => props.hMob || ' '};
margin-bottom: ${(props) => props.mbMob || '35px'};
font-weight:  ${(props) => props.fwMob || '400 '};
}
@media (max-width: 320px) {

}
`;

export const Paragraph24 = styled.p`
max-width: 450px;
height: ${(props) => props.h || ' '};
font-family: 'eliotpro';
font-style: normal;
font-weight:  ${(props) => props.fw || '400'};
font-size: 24px;
line-height: 26px;
color: #FFFFFF;
margin-bottom: ${(props) => props.mb || '7px'};
@media (max-width: 800px) {
width: ${(props) => props.wMob || ' '};
height: ${(props) => props.hMob || ' '};
margin-bottom: ${(props) => props.mbMob || '15px'};
font-weight:  ${(props) => props.fwMob || ' '};
}
@media (max-width: 320px) {

  margin-bottom: ${(props) => props.mb320Mob || '15px '};
}
`;

export const ItemRow = styled.div`
display:${(props) => props.dis || ' flex '} ;
flex-wrap: ${(props) => props.fw || ' wrap '} ;
width:${(props) => props.w || '100%'};
align-items: center;
align-content:${(props) => props.ac || ' center '};
justify-content:${(props) => props.jc || 'center'};
padding:${(props) => props.pad || ' '};
margin: ${(props) => props.m || ' '};
font-family: ${(props) => props.ff || 'eliotpro'};
font-weight: ${(props) => props.fw || ' '};
text-align: ${(props) => props.txal || ' '};
@media (max-width: 900px ){
  display:${(props) => props.dis900 || ' flex '} ;
  width:${(props) => props.w900 || ' '}   ;
  padding: ${(props) => props.pad900 || 'unset'} ;
  margin: ${(props) => props.m900 || ' '}; 
 
}


@media (max-width: 550px ){
  display:${(props) => props.dis900 || ' flex '} ;
  width:${(props) => props.w900 || ' '}   ;
  padding: ${(props) => props.pad900 || 'unset'} ;
  margin: ${(props) => props.m900 || ' '}; 
 text-align: ${(props) => props.tal900 || ' '}; 
}
`;

export const ItemCol = styled.div`
display:${(props) => props.dis || ' block '} ;
flex-wrap: ${(props) => props.wrap || ' wrap '} ;
width:${(props) => props.w || ' '};
max-width:${(props) => props.mw || ' '};
min-height:${(props) => props.minh || ' '};
align-items: center;
align-content:${(props) => props.ac900 || ' center '};
justify-content:${(props) => props.jc || 'center'};
padding:${(props) => props.pad || ' '};
margin:${(props) => props.m || ' 0 3px '};
background-color:${(props) => props.bgcolor || ' '};
font-family: ${(props) => props.ff || 'eliotpro'};
@media (max-width: 900px ){
  display:${(props) => props.dis900 || ' '} ;
  width:${(props) => props.w900 || ' '}   ;
  padding: ${(props) => props.pad900 || 'unset'} ; 
  margin: ${(props) => props.m900 || ' '}; 
}

@media (max-width: 550px ){
  width:${(props) => props.w550 || ''}; 
  min-height:${(props) => props.minh550 || ' '};
}

`;

export const Button = styled.button`
width: ${(props) => props.w || '181px'};
height: ${(props) => props.h || '48px'};
background:${(props) => props.bgck || '#0061A0'};
border-radius: 40px;
color: ${(props) => props.color || '#FFFFFF'};
font-family: 'eliotpro';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 18px;
text-align: center;
box-shadow:none;
margin:${(props) => props.m || ''} ;
border:${(props) => props.bder || 'none'};
margin-left:${(props) => props.ml || ''}; ;
transition: background-color 200ms cubic-bezier(0.4,0,0.2,1);

&:hover {
  cursor: pointer;
  /* background-color: ${(props) => props.HoverbackgroundColor || '#004887'};
  color:${(props) => props.Color || '#FFFFFF'};  */
}
@media (max-width: 800px) {
  
width:  ${(props) => props.wMob || ''};
height: ${(props) => props.hMob || ' '};
margin:${(props) => props.mMob || ''} ;
margin-bottom: ${(props) => props.mbMob || '15px'};
align-self: ${(props) => props.als || ' '} ;
}
`;

export const Image = styled.img`
  max-width: ${(props) => props.maxwidth || '100%'};
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || ' '};
  margin: ${(props) => props.margin};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.bottom || ''};
  margin-right: ${(props) => props.marginRight || ''};
  margin-left: ${(props) => props.marginLeft || ''};
  position: ${(props) => props.position || 'initial'};
  left: ${(props) => props.left || ''};
  top: ${(props) => props.top || ''};
  cursor: ${(props) => props.cursor || ' '};
  display: ${(props) => props.displayWeb || ' '};
  border: ${(props) => props.border || ' '};
  z-index: ${(props) => props.zindex || ''};
  transform: ${(props) => props.transform || ''};
  transform-origin: ${(props) => props.to || ''};
  max-height: ${(props) => props.mheight || ' '};
  @media (max-width: 800px) {
    margin-bottom: ${(props) => props.bottomMObile || ''};
    width: ${(props) => props.mwidth || props.width || '100%'};
    left: ${(props) => props.mleft || ''};
    top: ${(props) => props.mtop || ''};
    margin-right: ${(props) => props.marginRightM || ''};
    margin-top: ${(props) => props.marginTopM || ''};
    margin-left: ${(props) => props.marginLeftM || ''};
  }
  @media (max-width: 700px) {
    display: ${(props) => props.displayMobile || ' '};
    height: ${(props) => props.heightMobile || ' '};
  }
  @media (max-width: 460px) {
    width: ${(props) => props.mwidth || props.width || '100%'};
    height: ${(props) => props.heightMobile || ' '};
    display: ${(props) => props.displayMobile || ' '};
    margin-top: ${(props) => props.mtMob || ' '};
    margin-bottom: ${(props) => props.mbMob || ' '};
    margin-left: ${(props) => props.mlMob || ' '};
  }
`;

export const Color = styled.div`
width:${(props) => props.w || ' 10px'};
display: ${(props) => props.dis || 'block'};
height:${(props) => props.h || ' 10px'};
align-items: center;
padding:${(props) => props.pad || ' '};
margin:${(props) => props.pad || ' 10px 5px '};
background-color: ${(props) => props.bgcolor || ' '}; 
`;

export const DynamicDiv = styled.div`
display: ${(props) => props.dis || 'block'};
`;

