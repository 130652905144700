import React, { useEffect } from 'react'
import Simulador from './../LpFondos/components/Simulador';
import { useNavigate } from "react-router-dom";
import {
  ContainerSection,
  ContainerItems,
  ItemWrapper,
  ItemRow,
  ItemCol,
  Button,
  Color,
  DynamicDiv
} from "../assets/styles/StylesGeneric.styled";
import {
  Btnlink,
  Title,

} from "../assets/styles/Perfilador.styled";
import './../assets/styles/StylesSimulator.css'
import stack from "./../assets/images/SimulatorTool/stack.svg";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { makeStyles } from "@material-ui/core/styles";
import { Block } from '@material-ui/icons';


ChartJS.register(ArcElement, Tooltip, Legend);

const useStyles = makeStyles({
  content: {
    justifyContent: "center",
    height: "100%",
    alignItems: "center"
  }
});

const SimuladorTool = ({ setCapitalAcumuladoNeutral,
  capitalAcumuladoNeutral,
  setCapitalAcumuladoMinimo,
  capitalAcumuladoMinimo,
  setCapitalAcumuladoMaximo,
  capitalAcumuladoMaximo,
  setCapitalInvertido,
  capitalInvertido,
  setRendimiento,
  rendimiento,
  inversionInicial,
  setInversionInicial,
  inversionMensual,
  setInversionMensual,
  inversionNivelRiesgo,
  setInversionNivelRiesgo,
  duracionTipo,
  setDuracionTipo,
  duracionPlazo,
  setDuracionPlazo,
  nivelPortafolio,
  setnivelPortafolio,
  scrollTop,
  setScrollTop
}) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const data = {
    datasets: [
      {
        label: '# of Votes',
        data: [
          nivelPortafolio.deuda_nacional, nivelPortafolio.deuda_extranjera, nivelPortafolio.rv_nacional,
          nivelPortafolio.rv_internacional, nivelPortafolio.rv_emergente, nivelPortafolio.chequera_dolares,
          nivelPortafolio.liquidez],

        backgroundColor: [
          '#00EFE8',
          '#94D8FA',
          '#62B5E5',
          '#007FA3',
          '#315470',
          '#002855',
          '#BBE6FC',
        ],

        borderColor: [
          '#0076CF',
          '#0076CF',
          '#0076CF',
          '#0076CF',
          '#0076CF',
          '#0076CF',
        ],
        borderWidth: 1,
        cutout: 85
      },
    ],
  };
  const optionsMoneyFormat = { style: 'currency', currency: 'MXN' }
  const numberFormat = new Intl.NumberFormat('es-MX', optionsMoneyFormat)

  const [expanded, setExpanded] = React.useState('panel2');
  // Constantes de formulario sericios principal 
  const [urlService] = React.useState("https://serviciostest.principal.com.mx/PrincipalDigitalv2/User/CrearUsuario");
  const user = window.sessionStorage.getItem("uuid_principal_user");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };



  return (
    <>
      <ContainerSection className="mainSectionPerfilador" mh="280.08px" minh700='300px'>
        <Title>Ya estás a un solo paso de invertir</Title>
      </ContainerSection>
      <ContainerSection minh900=" " maxh900=" " h500Mob='unset' className="simulador">
        <ContainerItems disMob="column" h="1070px" h900="unset" m="80px auto" m900="auto" mw="65rem" br="30px" b="2px solid #E8E8E8" b900='none'>
          <ItemWrapper
            w="35%"
            br="30px 0 0 30px"
            dis900="none"
            bgcolor=" #F0FBFE"
            className="simulador-wrapper">
            <Simulador className='rounded'
              padding="35px 68px 56px 68px  "
              displayTable="none"
              displayRiskLevel="block"
              setCapitalAcumuladoNeutral={setCapitalAcumuladoNeutral}
              capitalAcumuladoNeutral={capitalAcumuladoNeutral}
              setCapitalAcumuladoMinimo={setCapitalAcumuladoMinimo}
              capitalAcumuladoMinimo={capitalAcumuladoMinimo}
              setCapitalAcumuladoMaximo={setCapitalAcumuladoMaximo}
              capitalAcumuladoMaximo={capitalAcumuladoMaximo}
              setCapitalInvertido={setCapitalInvertido}
              capitalInvertido={capitalInvertido}
              setRendimiento={setRendimiento}
              rendimiento={rendimiento}
              inversionInicial={inversionInicial}
              setInversionInicial={setInversionInicial}
              inversionMensual={inversionMensual}
              setInversionMensual={setInversionMensual}
              inversionNivelRiesgo={inversionNivelRiesgo}
              setInversionNivelRiesgo={setInversionNivelRiesgo}
              duracionTipo={duracionTipo}
              setDuracionTipo={setDuracionTipo}
              duracionPlazo={duracionPlazo}
              setDuracionPlazo={setDuracionPlazo}
              setnivelPortafolio={setnivelPortafolio}
            />
          </ItemWrapper>

          <ItemWrapper dis="none" dis900="flex" m900="2px 0" className="outer-wrapper">
            <Accordion sx={{ width: '90%', minHeight: '80px', backgroundColor: '#F0FBFE' }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary
                classes={{ content: classes.content }}
                sx={expanded === 'panel1' ? { height: '100%', maxHeight: '80px', alignSelf: 'center' } : { height: '100%', alignSelf: 'center' }}
                expandIcon={expanded === 'panel1' ? <RemoveIcon sx={{ color: '#0061A0', width: '35px', height: '35px' }} /> : <AddIcon sx={{ color: '#0061A0', width: '35px', height: '35px' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ color: '#0061A0', fontFamily: 'eliotpro', fontWeight: '700', fontSize: '18px', justifyContent: 'center' }}> Vuelve a simular tu inversión</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <ItemWrapper
                    br900="30px"
                    w900="100%"


                    bgcolor=" #F0FBFE"
                    b900='none'
                    className="simulador-wrapper 2">

                    <Simulador className='rounded'
                      padding="35px 68px 56px 68px  "
                      displayTable="none"
                      setScrollTop={setScrollTop}
                      displayRiskLevel="block"
                      setCapitalAcumuladoNeutral={setCapitalAcumuladoNeutral}
                      capitalAcumuladoNeutral={capitalAcumuladoNeutral}
                      setCapitalAcumuladoMinimo={setCapitalAcumuladoMinimo}
                      capitalAcumuladoMinimo={capitalAcumuladoMinimo}
                      setCapitalAcumuladoMaximo={setCapitalAcumuladoMaximo}
                      capitalAcumuladoMaximo={capitalAcumuladoMaximo}
                      setCapitalInvertido={setCapitalInvertido}
                      capitalInvertido={capitalInvertido}
                      setRendimiento={setRendimiento}
                      rendimiento={rendimiento}
                      inversionInicial={inversionInicial}
                      setInversionInicial={setInversionInicial}
                      inversionMensual={inversionMensual}
                      setInversionMensual={setInversionMensual}
                      inversionNivelRiesgo={inversionNivelRiesgo}
                      setInversionNivelRiesgo={setInversionNivelRiesgo}
                      duracionTipo={duracionTipo}
                      setDuracionTipo={setDuracionTipo}
                      duracionPlazo={duracionPlazo}
                      setDuracionPlazo={setDuracionPlazo}
                      setnivelPortafolio={setnivelPortafolio}
                    />
                  </ItemWrapper>
                </Typography>
              </AccordionDetails>
            </Accordion>


          </ItemWrapper>


          <ItemWrapper w="65%" wrap="wrap" pad="35px 0" >
            <ItemRow className='proyecciones'>
              <ItemRow dis='none' minh="80px" w="50%" w900='100%' m='20px 0 ' >
                <ItemRow className='cantidad-mob'>
                  {numberFormat.format(capitalAcumuladoNeutral)}
                </ItemRow>
                <ItemRow className='des'>
                  Monto estimado*
                </ItemRow>
              </ItemRow>
              <ItemCol bgcolor="#F6F6F8" minh="80px" w="30%" dis="flex" w900='35%' minh550='60px' w550='45%' ac900='center'>
                <ItemRow className='cantidad' >
                  {numberFormat.format(capitalAcumuladoMinimo)}
                </ItemRow>
                <ItemRow className='des'>
                  Escenario conservador
                </ItemRow>
              </ItemCol>

              <ItemCol bgcolor="#F6F6F8" minh="80px" w="30%" dis="flex" dis900="none" ac900='center'>
                <ItemRow className='cantidad' fw='700'  >
                  {numberFormat.format(capitalAcumuladoNeutral)}
                </ItemRow>
                <ItemRow className='des'>
                  Monto estimado*
                </ItemRow>
              </ItemCol>
              <ItemCol bgcolor="#F6F6F8" minh="80px" w="30%" dis="flex" w900='35%' minh550='60px' w550='45%'>
                <ItemRow className='cantidad'>
                  {numberFormat.format(capitalAcumuladoMaximo)}
                </ItemRow>
                <ItemRow className='des'>
                  Escenario optimista
                </ItemRow></ItemCol>

            </ItemRow>

            <ItemRow className='perfil' m900="25px 0 0 0 " >
              <ItemRow className='btext'>
                Perfil proyectado
              </ItemRow>
              <ItemRow m=" 20px 0 " fw='unset'>
                <ItemCol className='col1'>
                  <ItemRow className='parrafo' jc='right  '>
                    {numberFormat.format(capitalInvertido)}
                  </ItemRow>
                  <ItemRow className='btext' tal900="right">
                    Capital invertido
                  </ItemRow>
                </ItemCol>
                <ItemCol pad900="0 0 0 30px"> <img src={stack} className='chartImg'></img></ItemCol>
                <ItemCol className='col2'>
                  <ItemRow className='parrafo' jc='left'>
                    {numberFormat.format(rendimiento)}
                  </ItemRow>
                  <ItemRow className='btext'>
                    Rendimiento
                  </ItemRow>
                </ItemCol>
              </ItemRow>

              <ItemRow m=" 15px 0 " dis900='none'>

                <form action={urlService} method="post">

                  <input
                    id="user"
                    name="user"
                    type="hidden"
                    value={user}
                  />
                  <input
                    id="sourceonboarding"
                    name="sourceonboarding"
                    type="hidden"
                    value="F"
                  />
                  <input
                    id="tipoperfilamiento"
                    name="tipoperfilamiento"
                    type="hidden"
                    value="A"
                  />

                  <Button
                    type="submit"
                    m="0"
                    als="center"
                  >¡Ya quiero invertir!</Button>
                </form>

              </ItemRow>
              <ItemRow dis900='none' >
                <div className='parrafo'>
                  Podrás hacer los cambios que prefieras al momento de invertir.
                </div>
              </ItemRow>

            </ItemRow>

            <ItemRow>
              <ItemCol w="50%" w900='100%' m900='20px 0'>
                <ItemRow w900='85%' m900='auto'>
                  <ItemRow className='pregunta' jc='left'>
                    ¿En qué se invertiría mi dinero?
                  </ItemRow>
                  <ItemRow className='parrafo' m="10px 0">
                    Si lo que necesitas es liquidez, tu inversión se destinará a fondos de inversión ideados para que puedas contar con el 10% del total.
                  </ItemRow>

                </ItemRow >
                <ItemRow w900='85%' m900='auto'>
                  <div className='cartera'>
                    <ItemRow ff='eliotprobold' m='10px 3px 0 0' >

                      <ItemCol className='colors' w="5%">
                        <Color bgcolor="#F0FBFE" />
                      </ItemCol>
                      <ItemCol className='label' w="50%" ff='eliotprobold' >
                        <div className='item-label-first'>
                          {nivelPortafolio.portafolio_1}

                          {
                            nivelPortafolio.portafolio_2 !== null ? `+ ${nivelPortafolio.portafolio_2}` : ""}
                        </div>
                      </ItemCol>
                      <ItemCol className='concepts' w="15%">
                        <div className='percentages'>
                          <b></b>
                        </div>
                      </ItemCol>
                    </ItemRow>
                    <ItemRow ff='eliotprobold' >
                      <ItemCol className='colors' w="5%">
                        <Color bgcolor="#F0FBFE" />
                      </ItemCol>
                      <ItemCol className='label' w="50%" ff='eliotprobold' >
                        <div className='item-label-first'>
                          Tipo de activo
                        </div>
                      </ItemCol>
                      <ItemCol className='concepts' w="15%">
                        <div className='percentages'>
                          <b>%</b>
                        </div>
                      </ItemCol>
                    </ItemRow>
                    <ItemRow m='0 3px 10px 0'>

                      <ItemCol className='colors' w="5%">

                        {nivelPortafolio.deuda_nacional !== 0 ?
                          <Color bgcolor="#00EFE8" /> : ""}

                        {nivelPortafolio.deuda_extranjera !== 0 ?
                          <Color bgcolor="#94D8FA" /> : ""}

                        {nivelPortafolio.rv_nacional !== 0 ?
                          <Color bgcolor="#62B5E5" /> : ""}

                        {nivelPortafolio.rv_internacional !== 0 ?
                          <Color bgcolor="#007FA3" /> : ""}

                        {nivelPortafolio.rv_emergente !== 0 ?
                          <Color bgcolor="#315470" /> : ""}

                        {nivelPortafolio.chequera_dolares !== 0 ?
                          <Color bgcolor="#002855" /> : ""}

                        {nivelPortafolio.reportos !== 0 ?
                          <Color bgcolor="#004887" /> : ""}

                        {nivelPortafolio.liquidez !== 0 ?
                          <Color bgcolor="#BBE6FC" /> : ""}

                      </ItemCol>
                      <ItemCol className='label' w="47%" ff='eliotprobold' >
                        {nivelPortafolio.deuda_nacional !== 0 ?
                          <><div className='item-label'>
                            Deuda Nacional:

                          </div></> : ""}

                        {nivelPortafolio.deuda_extranjera !== 0 ?
                          <><div className='item-label'>
                            Deuda Extranjera:
                          </div></> : ""}

                        {nivelPortafolio.rv_nacional !== 0 ?
                          <><div className='item-label'>
                            RV Nacional:
                          </div></> : ""}

                        {nivelPortafolio.rv_internacional !== 0 ?
                          <><div className='item-label'>
                            RV Internacional:
                          </div></> : ""}

                        {nivelPortafolio.rv_emergente !== 0 ?
                          <><div className='item-label'>
                            RV Emergente:
                          </div></> : ""}

                        {nivelPortafolio.chequera_dolares !== 0 ?
                          <> <div className='item-label'>
                            Chequera Dólares:
                          </div></> : ""}

                        {nivelPortafolio.reportos !== 0 ? <>
                          <div className='item-label'>
                            Reportes:
                          </div></> : ""}

                        {nivelPortafolio.liquidez !== 0 ? <>
                          <div className='item-label'>
                            Liquidez:
                          </div></> : ""}


                      </ItemCol>
                      <ItemCol className='concepts' w="20%">
                        {nivelPortafolio.deuda_nacional !== 0 ? <>
                          <div className='percentages'>
                            {nivelPortafolio.deuda_nacional} %
                          </div></> : ""}

                        {nivelPortafolio.deuda_extranjera !== 0 ? <>
                          <div className='percentages'>
                            {nivelPortafolio.deuda_extranjera} %
                          </div></> : ""}

                        {nivelPortafolio.rv_nacional !== 0 ? <>
                          <div className='percentages'>
                            {nivelPortafolio.rv_nacional} %
                          </div></> : ""}

                        {nivelPortafolio.rv_internacional !== 0 ? <>
                          <div className='percentages'>
                            {nivelPortafolio.rv_internacional} %
                          </div></> : ""}

                        {nivelPortafolio.rv_emergente !== 0 ? <>
                          <div className='percentages'>
                            {nivelPortafolio.rv_emergente} %
                          </div></> : ""}

                        {nivelPortafolio.chequera_dolares !== 0 ? <>
                          <div className='percentages'>
                            {nivelPortafolio.chequera_dolares} %
                          </div></> : ""}

                        {nivelPortafolio.reportos !== 0 ? <>
                          <div className='percentages'>
                            {nivelPortafolio.reportos} %
                          </div> </> : ""}

                        {nivelPortafolio.liquidez !== 0 ? <>
                          <div className='percentages'>
                            {nivelPortafolio.liquidez}%
                          </div></> : ""}


                      </ItemCol>
                    </ItemRow>

                  </div>
                </ItemRow>
              </ItemCol>

              <ItemCol w="45%" w550='100%' w900='50%' pad='0px'>
                <ItemRow style={{ height: "40vh", width: "250px", textAlign: "center", margin: "auto" }}>
                  <Doughnut data={data} height={null} width={null} options={{ maintainAspectRatio: false }} />
                </ItemRow>
              </ItemCol>
            </ItemRow>
            {/*<ItemRow m='45px 3px 20px 0' className='cta' dis900='none'>
              < Btnlink onClick={(e) => {
                e.preventDefault();
                //window.location.href = '/'
                navigate('/perfilador')
              }}
              ><b>¿Quieres hacer tu propia cartera?</b></ Btnlink >
            </ItemRow>*/}

            <ItemRow m=" 40px 0 0 " dis='none'>
              <form action={urlService} method="post">

                <input
                  id="user"
                  name="user"
                  type="hidden"
                  value={user}
                />
                <input
                  id="sourceonboarding"
                  name="sourceonboarding"
                  type="hidden"
                  value="F"
                />
                <input
                  id="tipoperfilamiento"
                  name="tipoperfilamiento"
                  type="hidden"
                  value="A"
                />

                <Button
                  type="submit"
                  m="0"
                  als="center"
                >¡Ya quiero invertir!</Button>
              </form>
            </ItemRow>
            <ItemRow m=" 0 0 40px " dis='none' txal='center'>
              <div className='parrafo'>
                Podrás hacer los cambios que prefieras al momento de invertir.
              </div>
            </ItemRow>

          </ItemWrapper>

        </ContainerItems>

      </ContainerSection>



    </>
  )
}

export default SimuladorTool