import React, {useState, useEffect}from "react";
import { ApolloProvider } from "@apollo/client";
import client from "./config/apollo";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layout/Layout";
import LpFondos from "./LpFondos/LpFondos";
import Perfilador from "./Perfilador/Perfilador";
import SimuladorTool from "./SimuladorTool/SimuladorTool";
import { UserContext } from "./useContext/UserContext";
import useSimulator from "./hooks/useSimulador";
import { v4 } from 'uuid';
import ScrollTop from "./scrollTop"; 
import lambdas from "./config/lambdas/lambdas";
import Protected from "./utilities/Protected";
import auth1 from "./config/auth/auth";


const App = () => {
  const {
    inversionInicial,
    setInversionInicial,
    inversionMensual,
    setInversionMensual,
    inversionNivelRiesgo,
    setInversionNivelRiesgo,
    capitalAcumuladoNeutral,
    setCapitalAcumuladoNeutral,
    capitalAcumuladoMinimo,
    setCapitalAcumuladoMinimo,
    capitalAcumuladoMaximo,
    setCapitalAcumuladoMaximo,
    capitalInvertido,
    setCapitalInvertido,
    rendimiento,
    setRendimiento,
    duracionTipo,
    setDuracionTipo,
    duracionPlazo,
    setDuracionPlazo,
    nivelPortafolio,
    setnivelPortafolio, 
    isPassingForPerfiliador,
    setIsPassingForPerfiliador
  } = useSimulator()
   
 const auth =   () => {
      lambdas.Auth()
        .then((response) => {
           if (!response.data.Error) {
             sessionStorage.setItem('token', response.data.token)            
             
           }
        })
        .catch((error) => {
          console.log(error);
        });
} 
  
  const [uuid] = useState(sessionStorage.getItem('uuid_principal_user') || v4());
  const dataURL = () => {
    const URLsearch = window.location.search;
    const urlParams = new URLSearchParams(URLsearch);
    //Accedemos a los valores
    let utm_source = urlParams.get('utm_source');
    let utm_medium = urlParams.get('utm_medium');
    let utm_campaign = urlParams.get('utm_campaign');
    let browser=navigator.userAgent    
     const data = {
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        browser:browser,
        idSession: uuid
     }
       const token =   auth1.GetToken()
        .then((response) => {
         lambdas.FondosLead(data, response.data.access_token)
         .then((response) => {            
          })
          .catch((error) => {
            console.log(error.data);
          });
        })
  }
  useEffect(() => {
   auth();
   sessionStorage.setItem('uuid_principal_user', uuid)     
   dataURL()
  }, []) 

  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
      <ScrollTop>
        <Layout>
        <UserContext.Provider
          value={{ uuid }}
        >    
          <Routes>
            <Route exact path="/" element={<LpFondos
                                                inversionInicial={inversionInicial}
                                                setInversionInicial={setInversionInicial}
                                                inversionMensual={inversionMensual}
                                                setInversionMensual={setInversionMensual}
                                                inversionNivelRiesgo={inversionNivelRiesgo}
                                                setInversionNivelRiesgo={setInversionNivelRiesgo}
                                                setCapitalAcumuladoNeutral={setCapitalAcumuladoNeutral}
                                                capitalAcumuladoNeutral={capitalAcumuladoNeutral}
                                                setCapitalAcumuladoMinimo={setCapitalAcumuladoMinimo}
                                                capitalAcumuladoMinimo={capitalAcumuladoMinimo}
                                                setCapitalAcumuladoMaximo={setCapitalAcumuladoMaximo}
                                                capitalAcumuladoMaximo={capitalAcumuladoMaximo}
                                                setCapitalInvertido={setCapitalInvertido}
                                                capitalInvertido={capitalInvertido}
                                                setRendimiento={setRendimiento}
                                                rendimiento={rendimiento}
                                                duracionTipo={duracionTipo}
                                                setDuracionTipo={setDuracionTipo}
                                                duracionPlazo={duracionPlazo}
                                                setDuracionPlazo={setDuracionPlazo}
                                                nivelPortafolio={nivelPortafolio}
                                                setnivelPortafolio={setnivelPortafolio }
                />} />
                
            <Route exact path="/perfilador" element={<Perfilador 
                                                        inversionNivelRiesgo={inversionNivelRiesgo}
                                                        setInversionNivelRiesgo={setInversionNivelRiesgo}
                                                        setIsPassingForPerfiliador={setIsPassingForPerfiliador}
                                                      />
                                                      } />
                
            <Route exact path="/simulador" element={
            <Protected isPassingForPerfiliador={isPassingForPerfiliador}>
                <SimuladorTool
                  inversionInicial={inversionInicial}
                  setInversionInicial={setInversionInicial}
                  inversionMensual={inversionMensual}
                  setInversionMensual={setInversionMensual}
                  inversionNivelRiesgo={inversionNivelRiesgo}
                  setInversionNivelRiesgo={setInversionNivelRiesgo}
                  setCapitalAcumuladoNeutral={setCapitalAcumuladoNeutral}
                  capitalAcumuladoNeutral={capitalAcumuladoNeutral}
                  setCapitalAcumuladoMinimo={setCapitalAcumuladoMinimo}
                  capitalAcumuladoMinimo={capitalAcumuladoMinimo}
                  setCapitalAcumuladoMaximo={setCapitalAcumuladoMaximo}
                  capitalAcumuladoMaximo={capitalAcumuladoMaximo}
                  setCapitalInvertido={setCapitalInvertido}
                  capitalInvertido={capitalInvertido}
                  setRendimiento={setRendimiento}
                  rendimiento={rendimiento}
                  duracionTipo={duracionTipo}
                  setDuracionTipo={setDuracionTipo}
                  duracionPlazo={duracionPlazo}
                  setDuracionPlazo={setDuracionPlazo}
                  nivelPortafolio={nivelPortafolio}
                  setnivelPortafolio={setnivelPortafolio }
                />
            </Protected>
            } />
          </Routes>
          </UserContext.Provider>
        </Layout>
        </ScrollTop>
      </ApolloProvider>
    </BrowserRouter>
  );
};

export default App;
