import React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import {Row, Col}  from 'react-bootstrap'
import imageSrc from './../../assets/images/section-two/ImgSec2.png';
import one from './../../assets/images/section-two/1.svg';
import two from './../../assets/images/section-two/2.svg';
import three from './../../assets/images/section-two/3.svg';
import fourth from './../../assets/images/section-two/4.svg';


const useStyles = makeStyles((theme)=>({
  section: {
    maxWidth: '65rem',
    margin: '70px auto 20px  auto',
    backgroundColor: '#ffffff',
    padding: '0 30px 0 30px',
    '@media (max-width: 500px)': {
      padding: '0 30px 0 10px',
    }
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    height: '50px',
    alignContent: 'center',
    alignItems: 'center',
    fontWeight: '700',
    fontSize:'28px',
    fontFamily:'eliotprobold',
    '@media (max-width: 768px)': {
      fontWeight: '400',
      fontSize: '21px'
    },
    '@media (max-width: 500px)': {
      fontWeight: '400',
      fontSize: '18px',
      fontFamily:'eliotpro',
    }
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    paddingTop: '30px'
  },
  listContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '15px',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: '30px'
  },
  rowWidth: {
    width: '100%'
  },
  images:{
    width:'100%'
  },
  hideOnMovil:{
    '@media (max-width: 768px)': {
      display: 'none'
    }
  },
  listRow:{
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      paddingBottom: '25px'
    },
  },
  item:{
    marginLeft: '15px'
  },
  itemTitle:{
    fontWeight: '700',
    fontSize: '18px', 
    color: '#002855'
  },
  itemContent:{
    fontWeight: '400',
    fontSize: '18px' 
  },
  justCont:{
    justifyContent: "center",
  }
  }));
    
    
export default function SectionTwo() {
    const classes = useStyles();
    return <div className={classes.section} >
        <Row >
            <div className={classes.title}>
              <span>Invierte en minutos</span>
            </div>
          </Row>
          <Row className={classes.justCont}>
            <Col md={7} className={classes.hideOnMovil}>
            <div className={classes.imageContainer}>
                <img className={classes.images} src={imageSrc} alt="imgSectiontwo">
                </img>
            </div>
              
            </Col>
            <Col
              md={5} sm={9} xs={11}>
            <div className={classes.listContainer}>
               <Row className={classes.rowWidth}>
                 <div className={classes.listRow}>
                  <Col xs={3}>
                  <div className={classes.number}>
                    <img  src={one} alt="imgSectiontwo">
                    </img>
                  </div>
                 </Col>
                 <Col xs={9}>
                 <div className={classes.item}>
                    <div className={classes.itemTitle}>
                      Simula tu inversión
                    </div>
                    <div className={classes.itemContent}>
                    Descubre cómo podría crecer tu dinero.
                    </div>
                  </div>
                 </Col>
                 </div>
               </Row>
               <Row className={classes.rowWidth}>
                 <div className={classes.listRow}>
                  <Col xs={3}>
                  <div className={classes.number}>
                    <img  src={two} alt="imgSectiontwo">
                    </img>
                  </div>
                 </Col>
                 <Col xs={9}>
                 <div className={classes.item}>
                    <div className={classes.itemTitle}>
                    Personaliza  
                    </div>
                    <div className={classes.itemContent}>
                      Responde algunas preguntas para saber qué tipo de inversionista eres.
                    </div>
                  </div>
                 </Col>
                 </div>
               </Row>
               <Row className={classes.rowWidth}>
                 <div className={classes.listRow}>
                  <Col xs={3}>
                  <div className={classes.number}>
                  <img  src={three} alt="imgSectiontwo">
                    
                    </img>
                  </div>
                 </Col>
                 <Col xs={9}>
                 <div className={classes.item}>
                 <div className={classes.itemTitle}>
                    Obtén tu plan
                  </div>
                  <div className={classes.itemContent}>
                      De acuerdo a tu perfil, te ofreceremos  un plan de  inversión adecuado para ti. 
                  </div>
                  </div>
                 </Col>
                 </div>
               </Row>

               <Row className={classes.rowWidth}>
               <div className={classes.listRow}>
                 <Col xs={3}>
                  <div className={classes.number}>
                  <img  src={fourth} alt="imgSectiontwo">
                    </img>
                  </div>
                 </Col>
                 <Col xs={9}>
                 <div className={classes.item}>
                 <div className={classes.itemTitle}>
                    Contrata
                  </div>
                  <div className={classes.itemContent}>
                      Sube en línea tus datos y documentación básica  para invertir. 
                  </div>
                  </div>
                 </Col>
                 </div>
                 
               </Row>
            </div>
            </Col>
          </Row>
          </div>
        
  }