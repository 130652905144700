const IsInversionInicial = (value, strict) => {
  if(strict){
    if(typeof value === 'undefined' || value === '' || isNaN(parseFloat(value)) || (parseFloat(value) < 1000 ) ){
      return false
    }
    return true
  } else {
    if(value !== ''){
      if(typeof value === 'undefined' || isNaN(parseFloat(value)) ||  (parseFloat(value) < 1000 ) ){
        return false
      }
    }else{
      return null
    }
    return true
  }
}

const IsInversionMensual = (value, strict) => {
  if(strict){
    if(typeof value === 'undefined' || value === '' || isNaN(parseFloat(value)) || ((0 < parseFloat(value)) && (parseFloat(value) < 500) )){
    //if(typeof value === 'undefined' || value === '' || isNaN(parseFloat(value)) || (parseFloat(value) < 0) || (parseFloat(value) >= 1 && parseFloat(value) < 500) ){
      return false
    }
    return true
  } else {
    
    if(value !== ''){
      if(typeof value === 'undefined' || isNaN(parseFloat(value)) || ((0 < parseFloat(value)) && (parseFloat(value) < 500) ) ){
     // if(typeof value === 'undefined' || isNaN(parseFloat(value)) || (parseFloat(value) < 0) || (parseFloat(value) >= 1 && parseFloat(value) < 500) ){
        return false
      }
    }else{
      return null
    }
    return true
    
  }
}

const IsInversionNivelRiesgo = (value) => {
  const possible = ['Tradicional', 'Conservador', 'Moderado', 'Agresivo']

  if(!possible.includes(value))
    return false
  return true
}

const IsInversionLiquidez = (value) => {
  const possible = ['Si', 'No']

  if(!possible.includes(value))
    return false
  return true
}

const IsDuracionTipo = (value) => {
  const possible = ['anios', 'meses']

  if(!possible.includes(value))
    return false
  return true
}

const IsDuracionPlazo = (value, duracionTipo, strict) => {
  if(strict){
    if(typeof value === 'undefined' || value === '' || isNaN(parseInt(value)) || !(parseInt(value) >= 0) || (parseInt(value) > 60) ){
      return false
    }
    if(duracionTipo === 'meses' && value < 1){
      return false
    }
    return true
  } else {
    if(value !== ''){
      if(typeof value === 'undefined' || isNaN(parseInt(value)) || !(parseInt(value) >= 0) || (parseInt(value) > 60) ){
        return false
      }
    }
    return true
  }
}


export {IsInversionInicial, IsInversionMensual, IsInversionNivelRiesgo, IsDuracionTipo, IsDuracionPlazo , IsInversionLiquidez}