import { useState } from "react";

export default function useSimulator(){
  const [inversionNivelRiesgo, setInversionNivelRiesgo] = useState(
    "Tradicional"
  );

  const [duracionTipo, setDuracionTipo] = useState("meses");
  const [duracionPlazo, setDuracionPlazo] = useState("");

  const [inversionInicial, setInversionInicial] = useState("")
  const [inversionMensual, setInversionMensual] = useState(null);

  const [capitalAcumuladoNeutral, setCapitalAcumuladoNeutral] = useState(0);
  const [capitalAcumuladoMinimo, setCapitalAcumuladoMinimo] = useState(0);
  const [capitalAcumuladoMaximo, setCapitalAcumuladoMaximo] = useState(0);

  const [capitalInvertido, setCapitalInvertido] = useState(0);
  const [rendimiento, setRendimiento] = useState(0);
  const [scrollTop, setScrollTop] = useState(false);

  const [isPassingForPerfiliador, setIsPassingForPerfiliador] = useState(false);
  
  const [nivelPortafolio, setnivelPortafolio] = useState({
    chequera_dolares: "",
    color: "",
    deuda_extranjera: "",
    deuda_nacional: "",
    liquidez: "",
    maximo: "",
    minimo: "",
    neutral: "",
    recomendacion: "",
    reportos: "",
    rv_emergente: "",
    rv_internacional: "",
    rv_nacional: "",
            
  }); 
  return {
    inversionInicial,
    inversionMensual,
    inversionNivelRiesgo,
    capitalAcumuladoNeutral,
    capitalAcumuladoMinimo,
    capitalAcumuladoMaximo,
    capitalInvertido,
    rendimiento,
    duracionTipo,
    duracionPlazo,
    nivelPortafolio,
    scrollTop,
    isPassingForPerfiliador,
    setInversionNivelRiesgo,
    setInversionInicial,
    setInversionMensual,
    setCapitalAcumuladoNeutral,
    setCapitalAcumuladoMinimo,
    setCapitalAcumuladoMaximo,
    setCapitalInvertido,
    setRendimiento,
    setDuracionTipo,
    setDuracionPlazo,
    setnivelPortafolio,
    setScrollTop,
    setIsPassingForPerfiliador
  }
}