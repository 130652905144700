import styled, {css} from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextField from "@material-ui/core/TextField";



export const SimuladorBox = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 324px;
  width: 100%;
  background: #f0fbfe;
  padding:${(props) => props.p || "35px 63px 56px 63px "}; 
  border-radius: 10px;
  @media (max-width: 800px) {
    margin: auto;
  }
`;

export const SubContainer = styled.div`
width:100% ;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const Limpiar = styled.button`
  width: 33%;
  font-family: "eliotpro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #0061a0;
  margin-top: 10px;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  border-bottom: 2px dotted #0061a0;
  margin-left: 65px;
`;


export const Inp = styled.input`
  height: 48.92px;
  width: 192px;
  font-weight: 400;
  box-sizing: border-box;
  font-size: 16px;
  font-family: "eliotpro";
  background: #ffffff;
  border: 1px solid #333333;
  border-radius: 4px;
  color: #333333;
  transform: matrix(1, 0, 0, 1, 0, 0);
  margin-bottom: ${(props) => props.mbtn || " "};
`;

export const InpRadio = styled.input`
  box-sizing: border-box;
  width: 19.85px;
  height: 20px;

  border: 1px solid #00efe8;
  transform: rotate(180deg);
  margin-bottom: ${(props) => props.mbtn || " "};
  background-clip: content-box;
  &:checked:after {
    background: red;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

export const Select = styled.select`
  box-sizing: border-box;
  width: 90px;
  height: 26.95px;
  background: #ffffff;
  border: 1px solid #333333;
  border-radius: 4px;
  padding-right: 20px !important;
`;

export const Small = styled.small`
  color: #ff0000;
`;

export const TitleSimulador = styled.p`
  width: 189px;
  height: 38.93px;
  left: 1038px;
  top: 139.94px;

  /* Body text bold 3 */

  font-family: "eliotpro";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;

  /* Gray/ Gris 1 */

  color: #333333;
`;
export const RiesgoPrf = styled.p`
  width: 102px;

  font-family: "eliotpro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;

  padding-bottom: 0px;

  color: #333333;
`;

export const LabelSimulador = styled.p`
    width: ${(props) => props.w || " "}; 
  font-family: "eliotpro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: ${(props) => props.lh || "18px "};
  color: #333333;
  text-align: start;
  margin-top: ${(props) => props.mt || "15px "};
  margin-bottom: ${(props) => props.mb || "10px"};
  margin-right: 5px;
  z-index: 0;
  position: relative;
`;

export const ContainerLabelHelp = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;
  height: auto;
  padding: 0px;
  z-index: 0;
  position: relative;
  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const ConatainerInputAndSelect = styled.div`
  display: flex;
  flex-direction: row;
`;

export const HelpIconSim = styled.img`
  max-width: ${(props) => props.maxwidth || " "};
  width: ${(props) => props.width || "20px "};
  height: ${(props) => props.height || "20px "};
  margin: ${(props) => props.margin};
  margin-top: ${(props) => props.marginTop || "9px"};
  margin-bottom: ${(props) => props.bottom || "0px"};
  margin-right: ${(props) => props.marginRight || ""};
  margin-left: ${(props) => props.marginLeft || ""};
  position: ${(props) => props.position || "relative "};
  left: ${(props) => props.left || ""};
  top: ${(props) => props.top || ""};
  cursor: ${(props) => props.cursor || " "};
  display: ${(props) => props.displayWeb || " "};
  border: ${(props) => props.border || " "};
  z-index: ${(props) => props.zindex || "0"};
  transform: ${(props) => props.transform || ""};
  transform-origin: ${(props) => props.to || ""};
  max-height: ${(props) => props.mheight || " "};
  @media (max-width: 800px) {
    margin-bottom: ${(props) => props.bottomMObile || ""};
    width: ${(props) => props.mwidth || props.width || ""};
    left: ${(props) => props.mleft || ""};
    top: ${(props) => props.mtop || ""};
    margin-right: ${(props) => props.marginRightM || ""};
    margin-top: ${(props) => props.marginTopM || ""};
    margin-left: ${(props) => props.marginLeftM || ""};
  }
  @media (max-width: 700px) {
    display: ${(props) => props.displayMobile || " "};
    height: ${(props) => props.heightMobile || " "};
  }
  @media (max-width: 460px) {
    width: ${(props) => props.mwidth || props.width || ""};
    height: ${(props) => props.heightMobile || " "};
    display: ${(props) => props.displayMobile || " "};
    margin-top: ${(props) => props.mtMob || " "};
    margin-bottom: ${(props) => props.mbMob || " "};
    margin-left: ${(props) => props.mlMob || " "};
  }
`;

export const HoverableContainer = styled.div`
  z-index: 0;
  position: relative;
`;

export const ToolTipContainer = styled.div`
  z-index: 100;
  display: flex;
  height: ${(props) => props.h || " "};
  align-items: flex-start;
  padding: 11px 12px;
  position: absolute;
  max-width: ${(props) => props.maxw || "130px"};
  width: ${(props) => props.w || "100%"};
  left: ${(props) => props.lf || "150px"};
  bottom: ${(props) => props.btom || "20px"};
  background: #444040;
  border-radius: 20px 20px 20px 0px;
  @media (max-width: 400px){
		max-width: ${(props) => props.maxw400 || " "};
	}
`;
export const ToolTipText = styled.div`
  width: ${(props) => props.w || " "};
  font-family: "eliotpro";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.02em;
  color: #ffffff;
`;

export const Dis = styled.div`
 display: ${(props) => props.dis || " "};
 
`;


const colores = {
	borde: "#0075FF",
	error: "#F81934",
	exito: "#0091DA"
}

const Formulario = styled.form`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 20px;

	@media (max-width: 800px){
		grid-template-columns: 1fr;
	}
`;

const Label = styled.label`
	display: block;
	font-weight: 700;
	cursor: pointer;

	${props => props.valido === 'false' && css`
		color: ${colores.error};
	`}
`;

const ContainerBox = styled.div`
	display: flex;
  flex-direction:${(props) => props.fd || " "}; 
    margin-top:${(props) => props.mt || " "};   
    margin-bottom:${(props) => props.mb || " "}; 
 
`;



const ContainerIn = styled.div`
	 display:flex;
   margin-left: ${(props) => props.ml || " "}; ;
`;


const GrupoInput = styled.div`
	position: relative;
	z-index: 0;
`;

const Inpt = styled(TextField)`
 /* width: 100%; */
   height: 48.92px;
  width: 100%;
  font-weight: 400;
  box-sizing: border-box;
  font-size: 16px;
  font-family: "eliotpro";
  background: #ffffff;
  padding:8px 10px 8px;
  border: 1px solid #333333;
  border-radius: 4px;
  color: #333333;
  transform: matrix(1, 0, 0, 1, 0, 0);
  margin-bottom: ${(props) => props.mbtn || " "};

	&:focus {
		border: 3px solid ${colores.borde};
		outline: none;
		box-shadow: 3px 0px 30px rgba(163,163,163, 0.4);
	}

	.MuiInput-underline:before {
    border-bottom: none;
  }
  && .MuiInput-underline:hover:before {
    border-bottom: none;
  }
  /* focused */
  .MuiInput-underline:after {
    border-bottom:none;
  }
`;






const LeyendaError = styled.p`
	font-size: 12px;
	margin-bottom: 0;
	color: ${colores.error};
	display: none;

	${props => props.valido === 'true' && css`
		display: none;
	`}

	${props => props.valido === 'false' && css`
		display: block;
	`}
`;

const IconoValidacion = styled(FontAwesomeIcon)`
	position: absolute;
	right: 10px;
	bottom: 14px;
	z-index: 2;
	font-size: 16px;
	opacity: 0;

	${props => props.valido === 'false' && css`
		opacity: 1;
		color: ${colores.error};
	`}

	${props => props.valido === 'true' && css`
		opacity: 1;
		color: ${colores.exito};
	`}
`;

const ContenedorTerminos = styled.div`
	grid-column: span 2;

	input {
		margin-right: 10px;
	}

	@media (max-width: 800px){
		grid-column: span 1;
	}
`;

const ContenedorBotonCentrado = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	grid-column: span 2;

	@media (max-width: 800px){
		grid-column: span 1;
	}
`;

const Boton = styled.button`
	height: 45px;
	line-height: 45px;
	width: 30%;
	background: #000;
	color: #fff;
	font-weight: bold;
	border: none;
	border-radius: 3px;
	cursor: pointer;
	transition: .1s ease all;

	&:hover {
		box-shadow: 3px 0px 30px rgba(163,163,163, 1);
	}
`;

const MensajeExito = styled.p`
	font-size: 14px;
	color: ${colores.exito};
`;

const MensajeError = styled.div`
	height: 45px;
	line-height: 45px;
	background: #F66060;
	padding: 0px 15px;
	border-radius: 3px;
	grid-column: span 2;
	p {
		margin: 0;
	} 
	b {
		margin-left: 10px;
	}
`;

export {
	Formulario,
	Label,
	GrupoInput,
	Inpt,
	LeyendaError,
	IconoValidacion,
	ContenedorTerminos,
	ContenedorBotonCentrado,
	Boton,
	MensajeExito,
	MensajeError,
  ContainerBox,
  ContainerIn 
};